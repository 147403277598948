import React from "react";
import userStyles from "../Style/UserManagement.module.css"
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import Label from '@nokia-csf-uxr/ccfk/Label';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import { setProp } from "Store/Actions";
import { setUserInputFields, updateSelectedPersonas, updateRoles } from "Store/contentState/userManagementSlice";


const CommonEmailField = (prop) => {
  const dispatch = useDispatch();
  const userEmailAddress = useSelector((state: RootState) => state.userManagement.userData.emailAddress);

  const handleEmailChange = async (newEmail) => {
    dispatch(setUserInputFields({ field: 'emailAddress', value: newEmail }));

    // Email validation pattern to check complete email structure
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,}$/;
    const isValidEmail = emailPattern.test(newEmail);
    const lowerCaseEmail = newEmail.toLowerCase();

    if (!isValidEmail) {
      // Reset state when email is not valid
      dispatch(setProp({ slice: 'userManagement', key: 'isEmailEntered', value: false }));
      dispatch(setProp({ slice: 'userManagement', key: 'isAddingNokiaUser', value: false }));
      dispatch(updateSelectedPersonas([]));
      return;
    }

    // Set the email entered state to true
    dispatch(setProp({ slice: 'userManagement', key: 'isEmailEntered', value: true }));

    // Check if the email belongs to the company
    const isNokiaEmail = lowerCaseEmail.includes("nokia") || newEmail.toLowerCase().includes("nokia-bell-labs") || newEmail.toLowerCase().includes("nokia-sbell");

    // Update persona and related state based on email type
    if (isNokiaEmail) {
      dispatch(setProp({ slice: 'userManagement', key: 'isAddingNokiaUser', value: true }));
      dispatch(updateSelectedPersonas(["nokia"]));
    } else {
      dispatch(setProp({ slice: 'userManagement', key: 'isAddingNokiaUser', value: false }));
      dispatch(updateSelectedPersonas([]));
    }
    dispatch(setProp({ slice: 'userManagement', key: 'ootRoles', value: [] }));
    dispatch(setProp({ slice: 'userManagement', key: 'approverRole', value: [] }));
  };



  return (
    <>
      <div className={userStyles.gridItem}>
        <div className={`${userStyles.marginBottom}`}>
          {/* <div style={{width:'40%'}}> */}
          <Label className={userStyles.userLabel}>Email address <span style={{ color: '#C91514' }}>*</span></Label>
          <TextInput
            id="email-address"
            disabled={prop.mode === "edit"}
            value={userEmailAddress}
            variant={"outlined"}
            placeholder="Email address"
            onChange={(e) => handleEmailChange(e.target.value)}
            maxWidth={false}
          />
        </div>
      </div>
      {/* </div> */}
    </>
  )
}

export default CommonEmailField;