import React, { useState } from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import {
    AlertIcon,
    AlertMessage,
  } from '@nokia-csf-uxr/ccfk/Alert';
import OOT from "Constants/services/oot";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import InlineFeedbackMessage from '@nokia-csf-uxr/ccfk/InlineFeedbackMessage';
import { showModal } from "Store/auth";
import useFetchUsers from './hooks/useFetchUsers';


const DIALOG_HEIGHT = "50%";
const DIALOG_WIDTH = "50%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};

type IDeleteUserModalCloseProp = {
    onClose: () => void;
    userId: string;
    typeOfUser: string;
};



const DeleteUser = ({ onClose, userId, typeOfUser }: IDeleteUserModalCloseProp) => {
    const dispatch = useDispatch();
    const authSlice = useSelector((state: RootState) => state.authSlice)
    const paginationSelector = useSelector((state: RootState) => state.pagination);
    const {limitPerpage, offset} = paginationSelector.paginationProps;

    // const getAllUsersList = useUserActions(typeOfUser);

    const { getUsersList } = useFetchUsers();
   
    const [responseMsg, setResponseMsg] = useState('');
    const [successShow, setSuccessShow] = useState(false)
    const [errorMsg, setErrorMsg] = useState('');
    const [errorShow, setErrorShow] = useState(false);

   

    const handleOnClose = () => {
        if(authSlice.system.modal.variant == "ERROR"){
            dispatch(
                showModal({
                  msg: "",
                  panel: { title: "", msg: "" },
                  show: false
                })
              );
        }
        onClose(); 
    }
    


    const handleDeleteUser = async () => {
     try {
        const response = await OOT.diableUser(userId);
        if(response.status){
          setSuccessShow(true);
          setResponseMsg(response.msg);
          setTimeout(() => {
            // getAllUsersList();
            getUsersList(limitPerpage, offset, typeOfUser);
            handleOnClose();
          }, 3000)
        }else{
            setErrorShow(true);
            setErrorMsg(response.msg);
        }
     } catch (error) {
        setErrorShow(true);
        setErrorMsg(error.message);
     }
    }
    return (
        <>
            <Dialog
                isOpen={true}
                ariaHideApp={false}
                style={{ content: CONFIRM_ALERT_STYLE }}
            >
                <DialogTitle
                    title={"Delete user"}
                    buttonContent={<CloseCloseIcon />}
                    buttonProps={{
                        onClick: handleOnClose,
                    }}
                />
                <DialogContent isTopDividerVisible={true} isBottomDividerVisible={true}>
                {responseMsg && <InlineFeedbackMessage
                        variant="success"
                        size="medium"
                        closeButton
                        show={successShow}
                        onClose={() => setSuccessShow(false)}
                    >
                        {responseMsg}
                    </InlineFeedbackMessage>}
                    {errorMsg && <InlineFeedbackMessage
                        variant="error"
                        size="medium"
                        closeButton
                        show={errorShow}
                        onClose={() => setErrorShow(false)}
                    >
                        {errorMsg}
                    </InlineFeedbackMessage>}
                <AlertMessage
            iconProps={{ style: { display: 'none' }}}
            message="Are you sure you want to delete the user?"
            variant="CONFIRM"
          />
                
                

                </DialogContent>
                <DialogFooter>
                    {/* <Button onClick={onClose}>Close</Button> */}
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button variant="destructive" onClick={handleDeleteUser}>Delete</Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default DeleteUser;