import React from "react";
import { NavLink } from "react-router-dom";

import { SideDrawerItem } from "@nokia-csf-uxr/ccfk/SideDrawer";
import UserSettingsIcon from "@nokia-csf-uxr/ccfk-assets/icons/latest/user-settings.svg";
import marketplaceCartIcon from "../Assets/Icons/marketplace_cart_icon.svg";
import CalendarIcon from "@nokia-csf-uxr/ccfk-assets/icons/latest/calendar.svg";
import quotationIcon from "../Assets/Icons/quotations_icon.svg";
import adminIcon from '../Assets/Icons/user_icon.svg';
import ordersIcon from '../Assets/Icons/orders_icon.svg';
import rolesIcon from '../Assets/Icons/roles_icon.svg';
import { resetPaginationsProps } from "Store/contentState/paginationSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import { clearGlobalSearchText } from "Store/contentState/commonSlice";
import DashboardIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/dashboard.svg';
import matrix from '../Assets/Icons/matrix.png'
import CIQIcon from '../Assets/Icons/CIQ.png'
import { Tooltip } from "@nokia-csf-uxr/ccfk";
import { replaceUnderscoreWithSpace } from "Pages/admin/user-management/utils/constants";



const getIcon = (navItem) => {
  switch (navItem) {
    case 'marketplace':
      return marketplaceCartIcon;
    case 'quotations':
      return quotationIcon;
    case 'orders':
      return ordersIcon;
    case 'subscription':
      return CalendarIcon;
    case 'account':
      return UserSettingsIcon;
    case 'users':
      return adminIcon;
    case 'roles':
      return rolesIcon;
    case 'dashboard':
      return DashboardIcon;
    case 'products':
      return matrix;
    case 'radio_network_ciq':
      return CIQIcon

    default:
      return null; // Return a default icon or null if no match is found
  }
};



const LoadNavLinks = (navItem) => {
  const dispatch = useDispatch();
  const globalSearchText = useSelector((state: RootState) => state.common.globalSearchText);
  const sidBarToggleState = useSelector((state: RootState) => state.common.sideBarOpen);


  const handleTabCommonPropsReset = () => {
    // Check if the clicked navItem is one of the ones you want to trigger resetPaginationProps for
    if (globalSearchText) {
      dispatch(clearGlobalSearchText())
    }
    if (navItem === 'marketplace' || navItem === 'users') {
      dispatch(resetPaginationsProps()); // Dispatch resetPaginationProps action
    }
  }
  return (
    <NavLink key={navItem} to={`/${navItem}`} onClick={handleTabCommonPropsReset}>
      {({ isActive }) => (
        !sidBarToggleState ? (
          <Tooltip
            tooltip={replaceUnderscoreWithSpace(navItem.charAt(0).toUpperCase() + navItem.slice(1))}
            placement="right"
            disable={false}
          >
            <SideDrawerItem
              icon={<img alt={`Icon ${navItem}`} src={getIcon(navItem)} />}
              label={replaceUnderscoreWithSpace(navItem.charAt(0).toUpperCase() + navItem.slice(1))}
              selected={isActive}
            />
          </Tooltip>
        ) : (
          <SideDrawerItem
            icon={<img alt={`Icon ${navItem}`} src={getIcon(navItem)} />}
            label={replaceUnderscoreWithSpace(navItem.charAt(0).toUpperCase() + navItem.slice(1))}
            selected={isActive}
          />
        )
      )}
    </NavLink>
  );
};

export default LoadNavLinks;
