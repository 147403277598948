import React, { useEffect, useMemo, useCallback } from "react";
import ProductCard from "./ProductCard";
import classes from '../marketplace/Style/ProductCard.module.css';
import { RootState } from "Store/mainStore";
import { useDispatch, useSelector } from 'react-redux';

import Loader from "Components/Elements/Loader";
import { setLoader, setProducts, setShowFilter, setProductQueryFilter } from "Store/contentState/marketplaceSlice";
import OOT from "Constants/services/oot";
import { setProp } from "Store/Actions";
import Pagination from "./common/Pagination";
import profileHeader from '../../Assets/Icons/new-profile-banner.svg';
import noItemsImg from '../../Assets/Icons/NoData.svg';
import { Typography } from "@nokia-csf-uxr/ccfk";
import { setOffset } from "Store/contentState/paginationSlice";
import noProductsImage from "../../Assets/Icons/product_not_found.svg";
import useCartActions from "./hooks/useCartActions";
import { getAccountCurrency } from "./Utils/commons";
import SnackBar from "./common/SnackBar";




const ProductsCatalogPage = () => {
    const dispatch = useDispatch();
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const paginationSelector = useSelector((state: RootState) => state.pagination);
    const showMerchantProducts = marketplaceSelector.showMerchantProducts;
    const { filters, products, accountDetails, isProductsLoading, isGetProducts } = marketplaceSelector;
    //const { currentPage, limitPerpage } = marketplaceSelector.paginationProps
    const { currentPage, limitPerpage } = paginationSelector.paginationProps;
    const {addToCart} = useCartActions();
    const {productType, productSubType, showOnlyNokiaProducts, productPortfolio, productSortyby, onlyDeliverable} = marketplaceSelector.filters.selection;
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const searchText = useSelector((state: RootState) => state.common.globalSearchText);
    const totalProducts = marketplaceSelector.totalProductsCount;
    
    
    const handleAddToCart = (product) => {
        addToCart(product);
        setSnackbarMessage(`${product.product_description} added in the cart`);
    }

    const handleCloseSnackBar = () => {
        setSnackbarMessage(''); // Clear the snackbar message after it closes
    };



    const groupedData = useMemo(() => {
        if (products.length === 0) return {};
        return products.reduce((acc, product) => {
            const { product_type_id } = product;
            if (!acc[product_type_id]) {
                acc[product_type_id] = [];
            }
            acc[product_type_id].push(product);
            return acc;
        }, {});
    }, [products]);


    const filterToColumnMapping = {
        productType: 'product_data.product_id',
        productSubType: 'product_data.product_sub_type_id',
        productPortfolio: 'product_data.is_agent_model'
        // Add more mappings as necessary
    };
    

    const generateFilterConditions = useMemo(() => {
    let conditions = Object.entries(filters.selection)
        .filter(([key]) => filterToColumnMapping[key]) // Ensure the filter key has a corresponding column mapping
        .reduce((acc, [key, value]) => {
            const column = filterToColumnMapping[key];

            if (Array.isArray(value) && value.length > 0) {
                // If value is a non-empty array, use the IN operator
                acc.push(`${column} IN (${value.map(v => `'${v}'`).join(', ')})`);
            } else if (typeof value === 'string' && value.trim() !== '') {
                // If value is a non-empty string, use the '=' operator
                acc.push(`${column} = '${value}'`);
            }

            // Skip if value is an empty string or an empty array
            return acc;
        }, []);


        // Add default condition
        //conditions.push(`product_lifecycle_status = 'Ready to Delivery'`);
        if(showOnlyNokiaProducts){
            conditions.push('product_data.is_marketplace = 1 and product_data.is_agent_model = 0');
        }else{
            conditions.push(marketplaceSelector.productQueryFilter);
        }

        if(searchText.trim()){
            const searchCondition = `(product_data.id LIKE '%${searchText}%' OR product_data.product_description LIKE '%${searchText}%')`;
            conditions.push(searchCondition);
        }
        // Join conditions with 'AND' and prepend 'AND' if there are conditions
        if(marketplaceSelector.filters.selection.productPortfolio.includes('all')){
            conditions =  conditions.filter((e)=>e != "product_data.is_agent_model = 'all'")
        }  
        let conditionData = conditions.length > 0 ? `${conditions.join(' AND ')}` : '';
        if(marketplaceSelector.filters.selection.productPortfolio.includes("0") || marketplaceSelector.filters.selection.productPortfolio.includes("1") || marketplaceSelector.filters.selection.productPortfolio.includes('all')){
            if(marketplaceSelector.filters.selection.productSortyby === "AZ"){
                return  conditionData +" "+ "order by product_data.product_description asc"
            }
            else if(marketplaceSelector.filters.selection.productSortyby === "ZA"){
                return  conditionData +" "+ "order by product_data.product_description desc"
            }
            else if(marketplaceSelector.filters.selection.productSortyby === "lowhigh"){
                return  conditionData +" "+ "order by cast(pricelist_2_0.price_amount AS DECIMAL(10, 3)) asc, product_data.product_description asc"
            }
            else if(marketplaceSelector.filters.selection.productSortyby === "highlow"){
                return  conditionData +" "+ "order by cast(pricelist_2_0.price_amount AS DECIMAL(10, 3)) desc, product_data.product_description desc"
            }
            else{
                return  conditionData +" "+ "order by case when product_data.product_id = 'Accessories' then 9 else 1 end, product_data.product_description asc"
            }
        }
        else if(marketplaceSelector.filters.selection.productSortyby === "lowhigh"){
             return  conditionData +" "+ "order by CAST(pricelist_2_0.price_amount AS DECIMAL(10, 3)) ASC"
        }
        else if(marketplaceSelector.filters.selection.productSortyby === "highlow"){
            return  conditionData +" "+ "order by CAST(pricelist_2_0.price_amount AS DECIMAL(10, 3)) DESC"
        }
        else if(marketplaceSelector.filters.selection.productSortyby === "AZ"){
            return  conditionData +" "+ "order by product_data.product_description ASC"
        }
        else if(marketplaceSelector.filters.selection.productSortyby === "ZA"){
            return  conditionData +" "+ "order by product_data.product_description DESC"
        }
        else{
        return conditionData
        }
    }, [filters.selection, showOnlyNokiaProducts, marketplaceSelector.productQueryFilter, searchText]);



    // const getProducts = useCallback(async (filterConditions, offset) => {
    //     dispatch(setLoader({ key: "isProductsLoading", value: true }));
    //     try {
    //             let currency = getAccountCurrency(accountDetails[0]['currency'], accountDetails[0]['currencyisocode'])
    //             const response = await OOT.getAllProducts(accountDetails[0].id, currency, filterConditions, limitPerpage, offset);
    //             const productsCount = await OOT.getProductsCount(accountDetails[0].id, currency, filterConditions);
    //             if (response.data.length !== 0) {
    //                 dispatch(setProducts(response.data))
    //                 dispatch(setShowFilter(true));
    //             } else {
    //                 dispatch(setProducts([]));
    //                 // dispatch(setShowFilter(false));
    //             }
            
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         dispatch(setLoader({ key: "isProductsLoading", value: false }));
    //     }
    // }, [isGetProducts, currentPage, limitPerpage]);


    const getProducts = useCallback(async (filterConditions, offset) => {
        dispatch(setLoader({ key: "isProductsLoading", value: true }));
        try {
            let currency = getAccountCurrency(accountDetails[0]['currency'], accountDetails[0]['currencyisocode']);
            
            // Fetch products and product count concurrently
            const [response, productsCountResponse] = await Promise.all([
                OOT.getAllProducts(accountDetails[0].id, currency, filterConditions, limitPerpage, offset),
                OOT.getProductsCount(accountDetails[0].id, currency, filterConditions)
            ]);
    
            // Parse the product count
            const totalProducts = productsCountResponse?.data[0]?.total_products || 0;
    
            if (response.data.length !== 0) {
                dispatch(setProducts(response.data));
                dispatch(setShowFilter(true));
            } else {
                dispatch(setProducts([]));
                // dispatch(setShowFilter(false));
            }
    
            // Dispatch the product count if needed
            dispatch(setProp({slice:'marketplace', key:'totalProductsCount', value: totalProducts}));
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(setLoader({ key: "isProductsLoading", value: false }));
        }
    }, [isGetProducts, currentPage, limitPerpage]);

    










    useEffect(() => {
        const offset = (currentPage - 1) * limitPerpage;
        dispatch(setOffset(offset));
        // Check if initial fetch needs to be done
        if (isGetProducts) {
            let result = generateFilterConditions;
            getProducts(generateFilterConditions, offset);
            // Set initial fetch done
            //setInitialFetchDone(true);
        }
    }, [isGetProducts, generateFilterConditions, productSubType, productType, productPortfolio, productSortyby, currentPage,  limitPerpage, showOnlyNokiaProducts, onlyDeliverable]);


   

    if (isProductsLoading) {
        return (
            <div style={{ position: "absolute", inset: "50% 0" }}>
                <Loader />
            </div>
        );
    }

    return (
        <>
            <div style={{ minHeight: '500px', padding:'1rem' }}>
                {Object.keys(groupedData).map((familyId) => (
                    <div key={familyId}>
                        <h3 style={{ borderBottom: '1px solid #D7D7D7' }}>{familyId}</h3>
                        <div className={classes.gridContainer}>
                            {groupedData[familyId].map((product, index) => (
                                <ProductCard key={product.id + index} card={product} onAddToCart={handleAddToCart} />
                            ))}
                        </div>
                    </div>
                ))}



                {marketplaceSelector.products.length === 0 && isGetProducts  && <div style={{marginTop: '4rem', minHeight:'500px'}}>
                   
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={noProductsImage} alt="no-items-image" style={{height:'200px', maxWidth:"100%"}}/>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '5px' }}>
                        <h5 style={{ fontSize: '16px', fontWeight: 'bold', marginBlockStart: '0', marginBlockEnd: '0', marginBottom: '.5rem', textAlign: 'center' }}>Sorry, no products found</h5>
                        <Typography>There are no matching products for the selected criteria</Typography>
                        
                    </div>
                    

                </div>}
            </div>


            <SnackBar message={snackbarMessage} onClose={handleCloseSnackBar} />

            <div style={{ marginBottom: '1rem' }}>
                <Pagination
                    disableProp={marketplaceSelector.products}
                    // totalItems={totalProducts}
                    // type="product"
                />
            </div>
        </>
    );
};


export default ProductsCatalogPage;