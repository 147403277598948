import React from "react";
import FieldSet, { Legend, FieldSetContent } from '@nokia-csf-uxr/ccfk/FieldSet';
import { Label } from "@nokia-csf-uxr/ccfk";
import Checkbox, { CheckboxLabelContent } from '@nokia-csf-uxr/ccfk/Checkbox';
import PropTypes from 'prop-types';

const MultiCheckboxList = (props) => {
    const {
        data = [],
        selectedItems = [],
        onCheckboxSelect = () => { },
        isDisabled = false,
        ariaLabelPrefix = "checkbox",
    } = props;
    return (
        <>
            <FieldSet>
                <FieldSetContent>
                    {
                         data.map((item) => {
                            return (
                                <Label key={item.id}>
                                    <Checkbox
                                        checked={selectedItems.includes(item.id)}
                                        //@ts-ignore
                                        onChange={() => onCheckboxSelect(item.id)}
                                        inputProps={{ 'aria-label': item.value, value: `checkbox${item.id}` }}
                                        disabled={isDisabled}
                                    />
                                    <CheckboxLabelContent style={{color:'#333333', fontWeight:'400'}}>{item.value}</CheckboxLabelContent>
                                </Label>
                            )
                        })
                    }
                </FieldSetContent>
            </FieldSet>
            {/* {data.map((item)=> {

                return (
                    
                )
            }
               
            ))} */}

        </>
    )
}

MultiCheckboxList.propTypes = {
    data: PropTypes.any, // Array of checkbox data
    selectedItems: PropTypes.array, // Array of selected item IDs
    onCheckboxSelect: PropTypes.func, // Function to handle selection
    isDisabled: PropTypes.bool, // Whether checkboxes are disabled
    ariaLabelPrefix: PropTypes.string, // Prefix for ARIA labels
};

MultiCheckboxList.defaultProps = {
    selectedItems: [],
    isDisabled: false,
    ariaLabelPrefix: "checkbox",
};


export default MultiCheckboxList;