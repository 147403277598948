import { OOT_GLOBAL, apiTimeout, DATAMART_GLOBAL } from "Components/Misc/CaseDetails_System";
import { showModal } from "Store/auth";
import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
//https://corsproxy.io/? if this is the issue lets this will integrate from the OOT.
import { store } from "Store/mainStore";


class OOT_API {
  accessToken;
  email;
  idtoken;
  userID;
  redX;
  constructor() {
    this.interceptRequests();
  }
  init({ accessToken, email, idtoken, userID, redX }) {
    if (accessToken && email) {
      this.accessToken = accessToken;
      this.email = email;
      this.idtoken = idtoken;
      this.userID = userID;
      this.redX = redX;
    } else {
      return { msg: "Failed to initiate." };
    }
  }
  interceptRequests() {
    axios.interceptors.response.use(
      (response) => {
        if (
          parseInt(response.data.errorCode) === 401 &&
          (response.data.errorMessage
            .trim()
            .toUpperCase()
            .includes("TOKEN ERROR") ||
            response.data.errorMessage
              .trim()
              .toUpperCase()
              .includes("TOKEN EXPIRED"))
        ) {
          this.redX.dispatch(
            showModal({
              msg: "Please log in again now...",
              variant: "ERROR",
              title: response.data.errorMessage.trim().toUpperCase(),
              show: true,
            })
          );
          // Save current state
          this.setCurrentState();
          // Show the message 5 second and redirect the page to login
          setTimeout(() => {
            const msalInstance = new PublicClientApplication(msalConfig);
            //@ts-ignore
            msalInstance.loginRedirect({
              redirectStartPage: process.env.REACT_APP_REDIRECT_URI,
            });
          }, 3000);
        } else if (parseInt(response.data.errorCode) === 401) {
          this.speak(401);
        }
        else if (parseInt(response.data.errorCode) === 500) {
          this.speak(401);
        }
        if (!response.data.status) {
          this.speak(500, {
            msg: response.data.errorMessage,
            panel: { title: "More details", msg: response.data.stacktrace },
          });
        }
        return response;
      },
      (error) => {
        const info = { msg: error, panel: { title: "", msg: "" } };
        if (error.name === "AxiosError") {
          info.msg = error.name;
          info.panel.title = error.message;
          info.panel.msg = error.stack;
        }
        this.speak(500, info);
        return Promise.reject(error);
      }
    );
    axios.interceptors.request.use((request) => {
      // console.log(request, '*********')
      // if(request.url.includes('/offer') && request.method === 'post'){
      //   request.headers["Content-Type"] = "multipart/form-data";
      // }else{
      //   request.headers["Content-Type"] = "application/json; charset=UTF-8";
      // } 

      if (request.url.includes('/offer') && request.method === 'post') {
        // Check if the request data includes a file
        if (request.data instanceof FormData) {
          request.headers["Content-Type"] = "multipart/form-data";
        } else {
          request.headers["Content-Type"] = "application/json; charset=UTF-8";
        }
      } else {
        request.headers["Content-Type"] = "application/json; charset=UTF-8";
      }
      request.headers["Authorization"] = "Bearer " + this.accessToken;
      delete request.headers["idtoken"];
      request.headers["idtoken"] = OOT.idtoken;
      request.timeout = 1000 * apiTimeout;
      return request;
    });
  }
  speak(code, info = { msg: "", panel: { title: "", msg: "" } }) {
    console.log("code", code);
    console.log("info", info);
    switch (code) {
      case 401:
        this.redX.dispatch(
          showModal({
            msg: "Please Reload to continue , If you have any unsaved data, close this message and copy the data before logging in again. If not, please log in again now.",
            variant: "ERROR",
            title: "Session Timed Out",
            show: true,
          })
        );
        break;
      case 200:
        break;
      case 500:
        this.redX.dispatch(
          showModal({
            msg: info.msg,
            variant: "ERROR",
            title: "Something went wrong",
            show: true,
            panel: {
              msg: info.panel.msg,
              title: info.panel.title,
            },
          })
        );
        break;
    }
  }

  /**
   * Save current state in localstorage
   *
  */
  setCurrentState = () => {
    localStorage.setItem("dac-portal-state", JSON.stringify(store.getState()))
  }





  syncOffer = async ({ data, source = 'cui' }) => {
    const params = `?username=${this.email}&source=${source}`;
    const res = await axios({
      method: "post",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.syncOffer + params,
      data: data,
    });
    return res.data;
  };

  getOpportunity = async ({ scrm_id }) => {
    const params = `?_format=json&username=${this.email}&scrm_id=${scrm_id}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getOpportunity + params,
    });
    return res.data;
  };
  getCustomer = async ({ account_nid }) => {
    //this runs when scrm is invalid;
    const params = `?_format=json&username=${this.email}&account_nid=${account_nid}&requestor=marketplace`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getCustomer + params,
    });

    return res.data;
  };

  getCustomerByNid = async ({ nid }) => {

    //this runs when scrm is invalid;
    const params = `?_format=json&username=${this.email}&nid=${nid}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getCustomer + params,
    });

    return res.data;
  };

  getAccountCustomer = async (account_nid) => {
    //this runs when scrm is invalid;
    const params = `?_format=json&username=${this.email}&nid=${account_nid}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getAccountCustomer + params,
    });

    return res.data;
  };

  getCountryBands = async ({ country }) => {
    //this runs when scrm is invalid;
    const params = `?_format=json&country=${country}&username=${this.email}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getCountryBands + params,
    });
    return res.data;
  };
  getProducts = async ({ category, account }) => {
    //this runs when scrm is invalid;
    const params = `?_format=json&category=${category}&account=${account}&username=${this.email}&flatten=true`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getProducts + params,
    });

    return res.data;
  };
  getApplicationTab = async ({ account }) => {
    const params = `?_format=json&account=${account}&username=${this.email}`;
    const res = await axios({
      method: "get",
      url:
        OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getApplicationTab + params,
    });

    return res.data;
  };
  getConnectivityTab = async ({ account, radioBand }) => {
    const params = `?_format=json&account=${account}&username=${this.email}&radioBand=${radioBand}`;
    const res = await axios({
      method: "get",
      url:
        OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getConnectivitytab + params,
    });

    return res.data;
  };

  getEdgeTab = async ({ account }) => {
    //http://localhost:5000/api/get-edgeTab?_format=json&username={{email}}&account=1000001115
    const params = `?_format=json&account=${account}&username=${this.email}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getEdgeTab + params,
    });
    return res.data;
  };
  getDevicesTab = async ({ account }) => {
    //http://localhost:5000/api/get-deviceTab?_format=json&username={{email}}&account=1000001115
    const params = `?_format=json&account=${account}&username=${this.email}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getDevicesTab + params,
    });
    return res.data;
  };
  getServicesTab = async ({
    account,
    caseData,
    shipping_country_code,
    currency_code,
  }) => {
    const params = `?_format=json&account=${account}&username=${this.email}&shipping_country_code=${shipping_country_code}&currency_code=${currency_code}`;
    // const ndacServices = new Promise((rs, rj) => {
    //   return axios({
    //     method: "POST",
    //     url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getServicesTab + params,
    //     data: caseData
    //   }).then((res) => rs(res.data));
    // })
    console.log("pratima-notfyet", caseData);
    const res = await axios({
      method: "POST",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getServicesTab + params,
      data: caseData,
    });
    // const res = await axios.all([ndacServices, ndacProfServices]);
    return {
      //@ts-ignore
      mpw_services: res?.data.data,
      //@ts-ignore
      // ndac_professional_services: res[1].data
    };
  };

  getWarrantyTab = async ({ account }) => {
    const params = `?_format=json&account=${account}&username=${this.email}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getWarrantyTab + params,
    });
    return res.data;
  };

  /**
   * Subscription Management api calls
   * @returns
   */

  getSubscriptionQuesionneries = async () => {
    //http://localhost:8080/api/subscription-questionnaire?_format=json&username={{email}}
    if (this.email) {
      const params = `?_format=json&username=${this.email}`;
      const res = await axios({
        method: "get",
        url:
          OOT_GLOBAL.proxyUrl +
          OOT_GLOBAL.endPoints.getSubscriptionQuestionnaire +
          params,
      });
      return res.data;
    }
    return new Promise((resolve, reject) =>
      reject(new Error("Something went wrong"))
    );
  };

  getSubscriptionPartnerData = async (isRequestor = false) => {
    //http://localhost:8080/api/network_data?_format=json&username={{email}}
    if (this.email) {
      const params = isRequestor ? `/partner?_format=json&username=${this.email}&requestor=marketplace` : `/partner?_format=json&username=${this.email}`;
      const res = await axios({
        method: "post",
        url:
          OOT_GLOBAL.proxyUrl +
          OOT_GLOBAL.endPoints.getSubscriptionNetworkData +
          params,
      });
      return res.data;
    }
    return new Promise((resolve, reject) =>
      reject(new Error("Something went wrong"))
    );
  };

  getSubscriptionNetworkData = async (accountId) => {
    //http://localhost:8080/api/network_data?_format=json&username={{email}}
    if (this.email) {
      const params = `/account/${accountId}?_format=json&username=${this.email}`;
      const res = await axios({
        method: "post",
        url:
          OOT_GLOBAL.proxyUrl +
          OOT_GLOBAL.endPoints.getSubscriptionNetworkData +
          params,
      });
      return res.data;
    }
    return new Promise((resolve, reject) =>
      reject(new Error("Something went wrong"))
    );
  };

  updateSubscription = async (data) => {
    //http://localhost:8080/api/subscription-update?_format=json&username={{email}}
    const params = `?username=${this.email}`;
    const res = await axios({
      method: "post",
      url:
        OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.updateSubscription + params,
      data: data,
    });
    return res.data;
  };

  cancellationSubscription = async (data) => {
    //http://localhost:8080/api/subscription-cancellation?_format=json&username={{email}}
    const params = `?username=${this.email}`;
    const res = await axios({
      method: "post",
      url:
        OOT_GLOBAL.proxyUrl +
        OOT_GLOBAL.endPoints.cancellationSubscription +
        params,
      data: data,
    });
    return res.data;
  };

  /**
    * marketplace api calls
    * @returns
    */


  //get products data from datamart

  getAccountDetailsApi = async (one_star_id) => {
    const bodyParams = {
      "properties": [
        "account_master_sapbyd.account_id",
        "account_master_sapbyd.account_text",
        "account.acc_no__c",
        "account_master_currency_sapbyd.currency_id",
        "account_master_currency_sapbyd.currency_text",
        "account.billingcountry",
        "account.billingcountrycode",
        "account.currencyisocode",
        "account_master_sapbyd.one_star_id",
        "account_master_sapbyd.three_star_id",
        "account.alcr_record_type__c",
        "merchant_terms_and_conditions.terms_and_conditions",
        "account.partner_type__c",
        "account_master_sapbyd.customer_group_text",
        "account_master_sapbyd.distribution_channel_id ",
        "account_master_sapbyd.distribution_channel_text"

      ],
      "filter": "",
      "one_star_id": [one_star_id],
      "limit": 10,
      "offset": 0
    }

    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read"
      },
      method: "post",
      url: DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.getAccountData,
      data: bodyParams
    });

    return res.data;
  }



  getQuotationAPI = async (oneStarSellToParty, oneStarEndCustomer, filter = '', limit = 100) => {
    const offerProperties = [
      "offer_header_data.offer_id",
      "offer_header_data.field_ofr_order_link_to_salesfor",
      "offer_header_data.name",
      "offer_header_data.nid",
      "offer_header_data.one_star_number_account",
      "offer_header_data.one_star_number_customer_partner",
      "offer_header_data.offer_link",
      "offer_header_data.owner_email",
      "offer_header_data.offer_title",
      "offer_header_data.offer_created_date",
      "offer_header_data.field_offer_expiry_date",
      "offer_header_data.archived",
      "offer_header_data.field_ofr_order_cart_total",
      "offer_header_data.field_ofr_order_cat_prod_discoun",
      "offer_header_data.field_ofr_order_commercial_model",
      "offer_header_data.field_ofr_order_co_owners",
      "offer_header_data.field_ofr_order_currency",
      "offer_header_data.field_ofr_order_source",
      "offer_header_data.field_ofr_order_original_price",
      "offer_header_data.status",
      "offer_header_data.po_number",
      "offer_header_data.po_link_pdf_attachment_link",
      "offer_header_data.createdAt",
      "offer_header_data.oot_billing_address_id",
      "offer_header_data.oot_shipping_address_id"
    ]
    const data = {
      "properties": offerProperties,
      "filter": filter,
      "limit": limit,
      "offset": 0
    }
    //const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.productsData;

    const params = `?_format=json&sellToParty=${oneStarSellToParty}&endCustomer=${oneStarEndCustomer}`;
    //const url = 'http://localhost:3000/offer/getAllOffers';
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.getAllOffers;
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
      },
      method: "post",
      url: url + params,
      data: data
    });
    // res.data.data = this.modifyProductData(res.data.data)
    return res.data;
  }


  getUserAccessControlInfo = async (user_id) => {
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.user;
    //const url = "http://localhost:3000/user"
    const response = await axios.get(url, {
      headers: {
        'x-user-id': user_id,
      },
    });
    return response.data
  }


  getAccountDetailsByOneStarID = async (one_star_id) => {
    const params = `?_format=json&username=${this.email}&1star=${one_star_id}`;
    const res = await axios({
      method: "get",
      url: OOT_GLOBAL.proxyUrl + OOT_GLOBAL.endPoints.getAccountCustomer + params,
    });

    return res.data;
  }

  //product api
  getAllProducts = async (account_no, currency = "", filter = '', limitPerPage = 20, offset = 0) => {
    const productProperties = [
      "account_master_currency_sapbyd.currency_id",
      "account.name",
      "account_master_sapbyd.account_id",
      "account_master_sapbyd.account_text",
      "pricelist_2_0.sap_account_id",
      "pricelist_2_0.account_description",
      "pricelist_2_0.price_amount",
      "pricelist_2_0.priceamount_currencycode",
      "product_data.id",
      "product_data.product_id",
      "product_data.product_category_description",
      "product_data.product_description",
      "product_data.product_family_id",
      "product_data.product_type_id",
      "product_data.product_sub_type_id",
      "pricelist_2_0.price_list",
      "product_data.product_lifecycle_status",
      "product_data.is_agent_model",
      "product_data.image_url",
      "product_data.is_marketplace",
      "supplier_master_sapbyd.supplier_text",
      "product_supplier_data.supplier_id",
      "supplier_master_sapbyd.address_info->>'$.data[0].country_region_id'",
      "product_details.product_specifications",
      "product_details.image_base_url",
      "product_data.fulfillment_mode"
      // "product_data.supplier_id"
    ]
    const data = {
      "properties": productProperties,
      "filter": filter,
      "limit": limitPerPage,
      "offset": offset
    }
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.productsData;
    const params = account_no !== null ? `?_format=json&account=${account_no}` : `?_format=json&account=${account_no}&currency=${currency}`;
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read"
      },
      method: "post",
      url: url + params,
      data: data
    });
    res.data.data = this.modifyProductData(res.data.data)
    return res.data;
  }

  //get products count
  getProductsCount = async (account_no, currency = "", filter = '', limitPerPage = 20, offset = 0) => {
    let properties = {
        "type": "bar",
        "xaxis": "''",
        "yaxis": "count(product_data.product_id)",
        "xaxis_alias": "product_type",
        "yaxis_alias": "total_products"
    }
    const bodyData = {
        "properties": properties,
        "filter": filter,
        "limit": "100",
        "offset": 0
    }
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.productsData;
    const params = account_no !== null ? `?_format=json&account=${account_no}` : `?_format=json&account=${account_no}&currency=${currency}`;
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "report"
      },
      method: "post",
      url: url + params,
      data: bodyData
    });
    res.data.data = this.modifyProductData(res.data.data)
    return res.data;

  }

  //product api
  getAllMerchantProducts = async (account_no, filter = '', limitPerPage = 20, offset = 0) => {
    const productProperties = [
      "product_description_id",
      "product_id",
      "product_type_id",
      "product_supplier_status_text",
      "product_category_id",
    ]
    const data = {
      "properties": productProperties,
      "filter": filter,
      "limit": limitPerPage,
      "offset": offset
    }
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.productsData;
    const params = `?_format=json&account=${account_no}`;
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read"
      },
      method: "post",
      url: url + params,
      data: data
    });
    res.data.data = this.modifySupplierProductData(res.data.data)
    return res.data;
  }


  modifySupplierProductData = (data) => {
    const updatedProducts = data.map((item) => {
      const product_image = DATAMART_GLOBAL.blobProductPath + `${item.product_id}.png`;

      let updatedItem = {
        ...item,
        product_image,
      };


      return updatedItem;
    });
    return updatedProducts;
  }


  getAllMerchantOrders = async (filter = '', limitPerPage = 20, offset = 0) => {
    const productProperties = [
      "sales_order_id",
      "account_id",
      "accountnumber",
      "name",
      "market",
      "business_name",
      "sales_order_text",
      "status_header_id",
      "approval_status_id",
      "approval_status",
      "order_type",
      "so_createdAt",
      "invoiced_quantity",
      "invoiced_amount_transactional_currency",
      "address",
      "street",
      "city",
      "postal_code",
      "country_region",
      "country_id"
    ]
    const data = {
      "properties": productProperties,
      "filter": filter,
      "limit": limitPerPage,
      "offset": offset
    }
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.shadowOffer;
    const params = `?_format=json`;
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read",
        'x-factory': "sales_order"
      },
      method: "post",
      url: url + params,
      data: data
    });
    // res.data.data = this.modifySupplierProductData(res.data.data)
    return res.data;
  }

  getAllMerchantOrderItems = async (filter = '', limitPerPage = 20, offset = 0) => {
    const productProperties = [
      "sales_order_id",
      "sales_order_item_id",
      "external_item_reference_id",
      "delivery_status_item",
      "invoicing_status_item",
      "fulfillment",
      "customer_information_item_id",
      "internal_comment_item_id",
      "reason_for_rejection_item",
      "item_processing_type",
      "item_type",
      "parent_product",
      "product_id",
      "requested_date",
      "last_confirmed_date",
      "requested_quantity",
      "requested_quantity_sales_unit_of_measure",
      "confirmed_quantity",
      "confirmed_quantity_sales_unit_of_measure",
      "fulfilled_quantity",
      "fulfilled_quantity_sales_unit_of_measure",
      "confirmed_fulfilled_quantity",
      "invoiced_quantity",
      "invoiced_quantity_sales_unit_of_measure",
      "net_value_transactional_currency",
      "invoiced_amount_transactional_currency",
      "invoiced_amount",
      "number_of_sales_order_items",
      "createdAt",
      "updatedAt",
      "account_id",
      "supplier_id"
    ]
    const data = {
      "properties": productProperties,
      "filter": filter,
      "limit": limitPerPage,
      "offset": offset

    }
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.orderitems;
    const params = `?_format=json`;
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read",
        'x-factory': "sales_order"
      },
      method: "post",
      url: url + params,
      data: data
    });
    // res.data.data = this.modifySupplierProductData(res.data.data)
    return res.data;
  }

  modifyProductData = (data) => {
    const updatedProducts = data.map((item) => {
      const product_quantity = 1;
      const product_total_cost = (product_quantity * item.price_amount).toFixed(2);
      const product_image = DATAMART_GLOBAL.blobProductPath + `${item.id}.png`;

      let updatedItem = {
        ...item,
        product_quantity,
        product_total_cost,
        product_image,
      };

      //checking for subscription products , if subscription then adding duration by default as 1
      if (
        (item.product_type_id === 'Software' && item.product_sub_type_id === 'Applications') ||
        (item.product_type_id === 'Services' && item.product_sub_type_id === 'Subscription')
      ) {
        updatedItem.duration = 12;
      }

      return updatedItem;
    });

    return updatedProducts;
  }


  //offer api from datamart



  saveOffer = async ({ data, source = 'cui' }) => {
    const params = `?username=${this.email}&source=${source}`;
    const res = await axios({
      headers: {
        // "Content-Type": "multipart/form-data",
        'x-user-id': this.userID,
      },
      method: "post",
      //url: 'http://localhost:3000/offer' + params,
      url: DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.shadowOffer + params,
      data: data,
    });
    return res.data;
  };



  getAllUsers = async (filter, limitPerPage, offset, userType) => {
    console.log(userType, 'userType')
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.bulkUsers;
     //const url = 'http://localhost:3000/user/all'
    // const params = "?_format=json&method = fetch"
    const userProperties = [
      "users.id",
      "users.email",
      "users.active",
      "users.name",
      "user_roles.role",
      "users.type"
    ]
    const data = {
      "properties": userProperties,
      "filter": filter,
      "limit": limitPerPage,
      "offset": offset
    }
    const res = await axios({
      headers: {
        // "Content-Type": "multipart/form-data",
        'x-user-id': this.userID,
        'x-factory': userType
      },
      method: "post",
      url: url,
      data: data
    });

    return res.data;
  }



  //add user
  addEditUser = async (data, operation, x_factory) => {
    const params = `?operation=${operation}`
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.user + params
    //const url = `http://localhost:3000/user?operation=${operation}`
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'x-factory': x_factory
      },
      method: "post",
      url: url,
      data: data,
    });
    return res.data;
  }

  diableUser = async (data) => {
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.user
    //const url = 'http://localhost:3000/user'
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'disable-user-id': data,
        'x-factory': "user"
      },
      method: "delete",
      url: url,
    });
    return res.data;
  }



  uploadPOMarketplace = async (formData, source = 'cui') => {
    const res = await axios({
      headers: {
        //  'Content-Type': 'multipart/form-data',
        'x-user-id': this.userID,
      },
      method: "post",
      url: DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.uploadPO,
      //url: 'http://localhost:3000/offer/uploadPO',
      data: formData,
    })
    return res.data;
  }

  //get countries from datamart
  getAllCountries = async (filterCondition) => {
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.countryApi
    //const url = "http://localhost:3000/country"
    const data = {
      "properties": [
        "country.iso_2",
        "country.name",
        "country.is_merchant_products_allowed",
        "country.is_allowed_for_marketplace",
        "country.short_region_code"
      ],
      "filter": filterCondition,
      "limit": 300,
      "offset": 0
    }
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read"
      },
      method: "post",
      url: url,
      data: data
    });
    return res.data;
  }


  //save cart items to datamart
  saveCartDetailsAPI = async (data, queryParam) => {

    const params = `?${queryParam}`
    //const apiUrl = 'http://localhost:3000/offer/' + params
    const apiUrl = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.shadowOffer + params
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
      },
      method: "post",
      url: apiUrl,
      data: data,
    });
    return res.data;
  }


  //api to get product types
  getProductTypes = async (account_no, currency = "", filter) => {
    const productTypeProperties = [
      "distinct product_data.product_id"
    ]
    const data = {
      "properties": productTypeProperties,
      "filter": filter,
      "limit": 100,
      "offset": 0
    }
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.productsData;
    const params = account_no !== null ? `?_format=json&account=${account_no}` : `?_format=json&account=${account_no}&currency=${currency}`;
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        "operation": "read"
      },
      method: "post",
      url: url + params,
      data: data
    });
    return res.data;

  }

  //api to get product subtypes
  getProductSubTypes = async (account_no, currency = "", filter) => {
    const productSubTypeProperties = [
      "distinct product_data.product_sub_type_id"
    ]
    const data = {
      "properties": productSubTypeProperties,
      "filter": filter,
      "limit": 100,
      "offset": 0
    }
    const url = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.productsData;
    const params = account_no !== null ? `?_format=json&account=${account_no}` : `?_format=json&account=${account_no}&currency=${currency}`;
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        "operation": "read"
      },
      method: "post",
      url: url + params,
      data: data
    });
    return res.data;

  }

  getTermsConditions = async (ids, filter) => {
    const bodyParams = {
      "properties": [
        "merchant_terms_and_conditions.terms_and_conditions",
        "merchant_terms_and_conditions.merchant_id",
        "merchant_terms_and_conditions.version_id"

      ],
      "filter": filter,
      "one_star_id": ids,
      "limit": 1,
      "offset": 0
    }

    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read",
        'requested_info': "supplier"
      },
      method: "post",
      url: DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.getAccountData,
      data: bodyParams
    });

    return res.data;
  }


  getUserRoles = async (queryFilter) => {
    const bodyParams = {
      "properties": [
        "id",
        "name",
        "data_objects"
      ],
      "filter": queryFilter,
      "limit": 100,
      "offset": 0
    }
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read",
        'Content-Type': 'application/json'
      },
      method: "post",
      url: DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.getUserRoles,
      // url: `http://localhost:3000/roles/all`,
      data: bodyParams
    });
    return res.data;
  }

  saveUserRoles = async (data, operation) => {
    const params = `?operation=${operation}`
    //const apiUrl = 'http://localhost:3000/roles' + params
    const apiUrl = DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.saveUserRoles + params
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
      },
      method: "post",
      url: apiUrl,
      data: data,
    });
    return res.data;
  }

  //get hierachy data
  getHierarchyData = async (filter, x_factory) => {
    const bodyParams = {
      "properties": [
        "distinct id",
        "level"
      ],
      "filter": filter,
      "limit": 1000,
      "offset": 0
    }
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read",
        'x-factory': x_factory
      },
      method: "post",
      url: DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.hierachyLevels,
      // url: `http://localhost:3000/hierarchy`,
      data: bodyParams
    });
    return res.data;
  }

  getHierarchyDataUniq = async (filter, x_factory) => {
    const bodyParams = {
      "properties": [
        "distinct level4_id, level5_id, level6_id, level, id",
      ],
      "filter": filter,
      "limit": 1000,
      "offset": 0
    }
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read",
        'x-factory': x_factory
      },
      method: "post",
      url: DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.hierachyLevels,
      // url: `http://localhost:3000/hierarchy`,
      data: bodyParams
    });
    return res.data;
  }

  getAccountsForInternalExternal = async (filter, limit = 10, offset = 0, requestedInfo = "sell_to_party", oneStarId = []) => {
    const bodyParams = {
      "properties": [
        "business_name",
        "name",
        "account_id",
        "parent_account_number",
        "customer_account_number",
        "market",
        "l4_id",
        "l5_id",
        "l6_id",
        "l7_id",
        "customer_compliance",
        "accountnumber"
      ],
      "filter": filter,
      "one_star_id": oneStarId,
      "limit": limit,
      "offset": offset
    }
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read",
        'requested_info': requestedInfo
      },
      method: "post",
      url: DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.allAccounts,
      // url: `http://localhost:3000/v1/account`,
      // url: `http://localhost:3000/account_list`,
      data: bodyParams
    });
    return res.data;
  }

  getAccountsCount = async (filter, limit = 10, offset = 0, requestedInfo = "sell_to_party") => {
    const bodyParams = {
      "properties": [
        // "business_name",
        // "name",
        // "account_id",
        // "parent_account_number",
        // "customer_account_number",
        // "market",
        // "l4_id",
        // "l5_id",
        // "l6_id",
        // "l7_id",
        // "customer_compliance"
        "COUNT(account_id)",
        // "customer_compliance"
      ],
      "filter": "",
      "limit": limit,
      "offset": offset
    }
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read",
        'requested_info': requestedInfo
      },
      method: "post",
      url: DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.allAccounts,
      // url: `http://localhost:3000/account_list`,
      data: bodyParams
    });
    return res.data;
  }

  //get account details for merchant/supplier
  getAccountDetailsMerchant = async (filter, limit = 10, offset = 0, one_star_id = [], requested_info = "supplier") => {
    const bodyParams = {
      "properties": [
        "account_id",
        "name",
        "legalform_id",
        "legal_form",
        "status_id",
        "status",
        "incoterms_id",
        "incoterms",
        "payment_terms_id",
        "payment_terms",
        "purchase_order_currency",
        "address_info"
      ],
      "filter": filter,
      "supplier_id": one_star_id,
      "limit": limit,
      "offset": offset
    }
    const res = await axios({
      headers: {
        'x-user-id': this.userID,
        'operation': "read",
        'requested_info': requested_info
      },
      method: "post",
      url: DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.allAccounts,
      // url: `http://localhost:3000/v1/account`,
      data: bodyParams
    });
    return res.data;
  }


  //get azure applications for machines and whitelisted clients
  getAzureApplications = async (searchText) => {
    const params = `?searchText=${searchText}`
    const res = await axios({
      headers: {
        'x-user-id': this.userID,

      },
      method: "get",
      url: DATAMART_GLOBAL.baseUrl + DATAMART_GLOBAL.endPoints.applicationList + params,
      //url: `http://localhost:3000/application` + params,
    });
    return res.data;

  }




  //save bookmarked products to datamart

}
const OOT = new OOT_API();
export default OOT;
