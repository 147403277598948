import React, { memo, useEffect, createRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AppBanner,
  Avatar,
  Button,
  ButtonsRow,
  Tooltip,
  Typography,
} from "@nokia-csf-uxr/ccfk";
import { AppHeader } from "@nokia-csf-uxr/ccfk/App";
import {
  AppBannerContent,
  AppBannerDivider,
  AppBannerDrawer,
  AppBannerLogo,
  AppBannerName,
  AppBannerNameSecondary,
} from "@nokia-csf-uxr/ccfk/AppBanner";
import AppMenu from "@nokia-csf-uxr/ccfk/AppMenu";
import UserAccountSummary, {
  UserAccountSummaryButton,
  UserAccountSummaryDescription,
  UserAccountSummaryFooter,
  UserAccountSummaryHeader,
  UserAccountSummaryPopup,
  UserAccountSummaryUsername,
} from "@nokia-csf-uxr/ccfk/UserAccountSummary";
import { SignOutButton } from "Components/SignOutButton";
// import { patchToken } from "Store/auth";
import { IStore } from "Store/contentState/ts";
import { RootState } from "Store/mainStore";
import { setTheme } from "Store/theme";
import { getTokenExpiryTime, hiddenLogin } from "Utils";
import { useMsal } from "@azure/msal-react";
import { SideDrawerButton } from "@nokia-csf-uxr/ccfk/SideDrawer";
// import React, { memo, useEffect, useState } from "react";

import DialogModal from "Components/Elements/DialogModal";
import TesterPanel from "Components/Features/TesterPanel";
import NotificationNeutralIcon from "@nokia-csf-uxr/ccfk-assets/latest/NotificationNeutralIcon";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import { BadgeIcon, IconBadge } from "@nokia-csf-uxr/ccfk/Badge";
import PortalHelpLinks from "./PortalHelpLinks";
import OOT from "Constants/services/oot";
import { setUser, showLoader } from "Store/auth";
import { callMsGraph } from "graph";
import { KEY_CODES } from "@nokia-csf-uxr/ccfk/common";
import { setProp } from "Store/Actions"

const Header = () => {

  // const SideDrawerButtonRef = createRef(null);
  const SideDrawerButtonRef = createRef<HTMLButtonElement>();
  const [isSideDrawerVisible, setIsSideDrawerVisible] = useState(false);
  const userManagementSelector = useSelector((state: RootState) => state.userManagement);
  const commonSliceSelector = useSelector((state: RootState) => state.common);
  // const showButtonBadge = (!!(subMenuOneBadge + subMenuTowBadge) || activeAlarmsBadge) && !isSideDrawerVisible;
  const showButtonBadge = false;
  // const SideDrawerButtonProps = {
  //   badged: showButtonBadge,
  //   buttonProps: {
  //     ref: SideDrawerButtonRef,
  //     onClick: () => {
  //       setIsSideDrawerVisible(!isSideDrawerVisible);
  //     },
  //     "aria-label":
  //       showButtonBadge && !isSideDrawerVisible
  //         ? "Side Navigation Menu new items"
  //         : "Side Navigation Menu",
  //   },
  //   onKeyUp: (event) => {
  //     //event.key === KEY_CODES.ARROW_DOWN &&  sideDrawerRef.current.focus();
  //   },
  // };

  const SideDrawerButtonProps = {
    badged: showButtonBadge,
    buttonProps: {
      ref: SideDrawerButtonRef,
      onClick: () => {
        dispatch(setProp({ 'slice': 'common', 'key': 'sideBarOpen', 'value': !commonSliceSelector.sideBarOpen}));
        
      },
      'aria-label': showButtonBadge && !isSideDrawerVisible ? 'Side Navigation Menu new items' : 'Side Navigation Menu',
    },
    onKeyUp: (event) => { 
      //event.key === KEY_CODES.ARROW_DOWN &&  sideDrawerRef.current.focus();
    },
  };
  const dispatch = useDispatch();
  const loginSelector = useSelector((state: IStore) => state.authSlice);
  const themeSelector = useSelector((state: RootState) => state.theme);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const { instance, accounts } = useMsal();
  const firstName = loginSelector.login.givenName;
  const lastName = loginSelector.login.surname;
  // const isTester = useMemo(() => authSelector.tester, [authSelector.role]);
  const { warn, error } = authSelector.system.stack;
  const magicLoginLink = `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=id_token%20token&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=openid+profile+email&response_mode=fragment&state=12345&nonce=${authSelector.login.mail}678910&prompt=none&login_hint=${authSelector.login.mail}`;

  const forceTokenRenewal = async () => {
    try {
      const silentRequest = {
        account: accounts[0],
        scopes: ["User.Read", "Application.Read.All"],
        forceRefresh: true, // Force a new token refresh
      };

      // Acquire a new token silently with refreshed cache
      const response = await instance.acquireTokenSilent(silentRequest);

     
      return response;
    } catch (error) {
      console.error("Token | Error forcing token renewal", error);
      document.querySelector('#silent-login').innerHTML = "Error Extending Token, Reloading in 3 secs."
      setTimeout(() => window.location.reload(), 3000)
      // Handle error
    }
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && toast) {
      if (authSelector.system.stack.error.length > 0) {
        authSelector.system.stack.error.forEach((e) =>
          toast.error(
            e.errorCode ? e.errorCode + ": " + e.errorMsg : e.errorMsg
          )
        );
      }
      if (authSelector.system.stack.warn.length > 0) {
        authSelector.system.stack.warn.forEach((e) =>
          toast.warn(
            e.errorCode ? e.errorCode + ": " + e.errorMsg : e.errorMsg,
            {
              pauseOnHover: true,
            }
          )
        );
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [error, warn]);

  
  const [countDown, setCountDown] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const timeScore = (rT: string) => parseInt(rT.toString().match(/\d+/g).join(''));;
  useEffect(() => {
    if (authSelector.login.adToken && authSelector.login.adToken.hasOwnProperty('idToken')) {
      //Check if an Interval is running and viceversa
      var intervalId = setInterval(async () => {
        let rT: any = getTokenExpiryTime(authSelector.login.adToken.idToken);
        const timeString = rT !== -1 ? rT.split(" ")[0].trim() : null;

        if (rT === -1) {
          setCountDown((orT) => 0);
        }
        if (rT !== -1 && timeString && ((timeString.includes('m') && parseInt(timeString) <= 5) || (!timeString.includes('m') && timeString.includes('s')))) {
          setCountDown((orT) => rT);
        }
        // console.log('token',timeScore(rT),"limit",timeScore(process.env.REACT_APP_showExtendToken ?? "30m"),'real',rT)
        if ( (timeScore(rT) === timeScore(process.env.REACT_APP_showExtendToken ?? "30m")) || (rT== -1)) {
          if (!themeSelector.data.popUp.isOpen.header_tokenExpire) {
            setShowTimer(() => true)
            dispatch(setTheme({
              element: "popUp",
              comp: "header_tokenExpire",
              prop: "isOpen",
              value: true,
            }))

          }

        }
      }, 1000);

    }
    return () => {
      clearInterval(intervalId);
    };
  }, [authSelector?.login?.adToken?.idToken])

  const handleUserAccountSummaryClick = () => {
    dispatch(
      setTheme({
        element: "dropDown",
        comp: "header_userAccountSummary",
        prop: "isOpen",
        value: !themeSelector.data.dropDown.isOpen.header_userAccountSummary,
      })
    );
  };

  return (
    <>
        <DialogModal isOpen={themeSelector.data.popUp.isOpen.header_tokenExpire} popInfo={{ title: 'Your session is about to expire', msg: "Please refresh the token to extend time" }} type="popup" actionBtns={
                [{
                    label: "Extend Time", variant: "call-to-action",
                    onAction: async () => {
                        document.querySelector('#silent-login').innerHTML = "Please Wait, Connecting with MSAL.."
                        let response = await forceTokenRenewal()
                        const adToken = response;
                        //@ts-ignore
                        const accessToken: any = response.accessToken;
                        //@ts-ignore
                        let callMS = await callMsGraph(response.accessToken)
                            .then((response) => {
                                setShowTimer(() => false)
                                setCountDown(() => 0)
                                dispatch(showLoader(false));
                                response["accessToken"] = accessToken;
                                response["adToken"] = JSON.stringify(adToken);
                                dispatch(setUser(response));
                                OOT.init({
                                    accessToken: accessToken,
                                    email: response.mail,
                                    idtoken: response.adToken.idToken,
                                    userID: response.id,
                                    redX: {
                                        dispatch: dispatch,
                                    }
                                });
                                document.querySelector('#silent-login').innerHTML = "Your Time has been extended"
                                dispatch(setTheme({ element: "popUp", comp: "header_tokenExpire", prop: "isOpen", value: false, }))

                            })
                    }
                }, {
                    label: "Logout",
                    variant: "neutral",
                    onAction: async () => {
                        dispatch(setTheme({
                            element: "popUp",
                            comp: "header_tokenExpire",
                            prop: "isOpen",
                            value: false,
                        }))

                        await instance.logoutRedirect({}).then(() => {
                            // Handle successful logout
                        }).catch((error) => {
                            // Handle logout error
                        });
                    }
                }]

            } >
                <Typography>
                    <div id="silent-login"></div>
                </Typography>

            </DialogModal>

      <div className="">
        <ToastContainer />
        <AppHeader style={{ boxShadow: "none" }}>
          <AppBanner>
         
            <SideDrawerButton {...SideDrawerButtonProps}/>
            <AppBannerLogo />
            <AppBannerName>Digital Automation for Campus</AppBannerName>
            <AppBannerNameSecondary>Business Hub</AppBannerNameSecondary>
            <AppBannerContent>
              <AppBannerDrawer>
                <AppMenu id="ToolUpdates">
                  <Tooltip tooltip="Tool updates" placement="bottom">
                    <Button
                      onClick={(event) => {
                        event.persist();
                        window.open(
                          `${process.env.REACT_APP_TOOL_UPDATE_URI}`,
                          "_blank"
                        );
                      }}
                    >
                      <ButtonText>Tool updates</ButtonText>
                      <IconBadge
                        variant="status"
                        status="critical"
                        className="bellIcon"
                      >
                        <BadgeIcon color="var(--ff-color-red-600)">
                          <NotificationNeutralIcon />
                        </BadgeIcon>
                      </IconBadge>
                    </Button>
                  </Tooltip>
                </AppMenu>
                <AppBannerDivider />
                <PortalHelpLinks />
                <AppBannerDivider />
                <AppMenu
                  onEscFocusOut={() => {
                    dispatch(
                      setTheme({
                        element: "dropDown",
                        comp: "header_userAccountSummary",
                        prop: "isOpen",
                        value: false,
                      })
                    );
                  }}
                  onClickOutside={() => {
                    dispatch(
                      setTheme({
                        element: "dropDown",
                        comp: "header_userAccountSummary",
                        prop: "isOpen",
                        value: false,
                      })
                    );
                  }}
                >
                  <UserAccountSummary>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Avatar>{`${firstName ? firstName.charAt(0) : "."}${
                        lastName ? lastName.charAt(0) : "."
                      }`}</Avatar>
                      <UserAccountSummaryButton
                        onClick={handleUserAccountSummaryClick}
                      >
                        {loginSelector.login.displayName}
                      </UserAccountSummaryButton>
                    </div>
                    <UserAccountSummaryPopup
                      open={
                        themeSelector.data.dropDown.isOpen
                          .header_userAccountSummary
                      }
                    >
                      <UserAccountSummaryHeader>
                        <UserAccountSummaryUsername
                          style={{ fontSize: "20px" }}
                        >
                          {loginSelector.login.displayName}
                        </UserAccountSummaryUsername>
                        <UserAccountSummaryDescription>
                          {loginSelector.login.jobTitle}
                        </UserAccountSummaryDescription>
                        <TesterPanel />
                      </UserAccountSummaryHeader>
                      {/* <UserAccountSummaryList isOverflowNecessary={false}>

                    </UserAccountSummaryList> */}
                      <UserAccountSummaryFooter>
                        <ButtonsRow>
                          <SignOutButton />
                        </ButtonsRow>
                      </UserAccountSummaryFooter>
                    </UserAccountSummaryPopup>
                  </UserAccountSummary>
                </AppMenu>

                {/*
                  (true) ? (<AppMenu>
                    {countDown}
                  </AppMenu>) : (<Skeleton style={{ width: '30' }} />)
                */}
                {/* <AppMenuButton>r</AppMenuButton> */}
              </AppBannerDrawer>
            </AppBannerContent>
          </AppBanner>
        </AppHeader>
      </div>
    </>
  );
};

export default memo(Header);
