import React from "react";
import { App, Typography } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector } from "react-redux";

import { RootState } from "Store/mainStore";



const FallbackPage = () => {
    const authSelector = useSelector((state: RootState) => state.authSlice);
    return (
        <LoadingOverlay
            active={authSelector.system.isLoading}
            spinner
            classNamePrefix="MyLoader_"
            text="Please wait "
        >
            <App>
                <Header />
                <div>
                    <h2>Page not found</h2>
                </div>
            </App>

        </LoadingOverlay>

    )

}

export default FallbackPage;