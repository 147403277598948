import React, { useRef, useState, useEffect } from "react";
import FilterField, { FilterFieldIcon } from "@nokia-csf-uxr/ccfk/FilterField";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "Store/contentState/marketplaceSlice";
import { RootState } from "Store/mainStore";
import PropTypes from "prop-types";
import { setGlobalSearchText, clearGlobalSearchText } from "Store/contentState/commonSlice";
import { setCurrentPage } from "Store/contentState/paginationSlice";

const SearchBox = ({ placeholder = 'Search...' }) => {
    const dispatch = useDispatch();
    const searchTextFromStore = useSelector((state: RootState) => state.common.globalSearchText);
    const [searchValue, setSearchValue] = useState(searchTextFromStore || ''); 
    const inputRef = useRef(null);
    

    // Sync local state with Redux state
    useEffect(() => {
        setSearchValue(searchTextFromStore);
    }, [searchTextFromStore]);

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            dispatch(setCurrentPage(1));
            dispatch(setGlobalSearchText(e.target.value));
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (value === '') {
            dispatch(setGlobalSearchText(value));
        }
    };

    const handleClear = () => {
        setSearchValue('');
        dispatch(clearGlobalSearchText());
    };

    return (
        
            <FilterField
                value={searchValue}
                onChange={handleChange}
                variant="outlined"
                textInputProps={{
                    placeholder: placeholder,
                    showClearButton: true,
                    clearButtonProps: {
                        onClick: handleClear,
                    },
                    inputProps: {
                        'aria-label': 'Search',
                        onKeyDown: handleSearch,
                        ref: inputRef, // Ref applied directly to the input element
                        
                    },
                }}
                renderIcon={<FilterFieldIcon title="click enter to search" />}
                
            />
        
    );
};

export default SearchBox;

SearchBox.propTypes = {
    placeholder: PropTypes.string,
};
