import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import profileHeader from '../../../Assets/Icons/new-profile-banner.svg';
import noItemsImg from '../../../Assets/Icons/NoData.svg';
import { Typography } from "@nokia-csf-uxr/ccfk";

const CatalogueLandingPage = () => {
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const { isGetProducts } = marketplaceSelector;
    return (
        <>
        {
            (marketplaceSelector.filters.selection.partner === '' || marketplaceSelector.filters.selection.customer === '' || marketplaceSelector.filters.selection.shippingCountry === '' || !isGetProducts) && <div style={{ minHeight: '500px' }}>
            <div style={{ margin: "2px" }}>
                <img src={profileHeader} style={{ width: '100%' }} alt="empty-state" />

            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem' }}>
                <img src={noItemsImg} alt="no-items-image" />
            </div>
            <div style={{ textAlign: 'center', marginTop: '5px' }}>
                <h5 style={{ fontSize: '16px', fontWeight: 'bold', marginBlockStart: '0', marginBlockEnd: '0', marginBottom: '.5rem', textAlign: 'center' }}>No items to show now</h5>
                <Typography>The products will appear here after selecting </Typography>
                <Typography>the account and customer, if applicable. </Typography>
            </div>
        </div>
        }

        </>
    )

}

export default CatalogueLandingPage;