
import React, {useEffect} from "react";
import Chip, { ChipLabel, ChipIcon } from '@nokia-csf-uxr/ccfk/Chip';
import partnerDiscountIcon from '../../../Assets/Icons/partner_discount_icon.svg';
import timeBasedDiscountIcon from '../../../Assets/Icons/timebased_discount_icon.svg';
import noDiscountIcon from '../../../Assets/Icons/no_discount_icon.svg';
import { useDispatch, useSelector } from "react-redux";
import { setProp } from "Store/Actions";
import { RootState } from "Store/mainStore";



const CartDiscounts = (prop) => {
    const { discount } = prop;
    const dispatch = useDispatch();
    

    const discounts = [
        { label: 'Partner discount', value: discount.ptd_percentage, icon: partnerDiscountIcon, backgroundcolor:"#E8FBFF", border:"1px solid #80E6FF"},
        { label: 'Volume discount', value: discount.vd_percentage, icon: partnerDiscountIcon, backgroundcolor:"#E8FBFF", border:"1px solid #80E6FF"},
        { label: 'Time based discount', value: discount.tbd_percentage, icon: timeBasedDiscountIcon, backgroundcolor:"#F0FDDC", border:"1px solid #C4F07D" }
    ];

    

    // const validDiscounts = discounts.filter(discount => discount.value !== 0);
    const validDiscounts = discounts.filter(discount => discount.value !== undefined && discount.value !== 0);


    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                {validDiscounts.length > 0 ? (
                    validDiscounts.map((discount, index) => (
                        <Chip key={index} size="small" style={{ backgroundColor: discount.backgroundcolor, border: discount.border, marginRight: '.5rem', borderRadius: '15px' }}>
                            <ChipIcon><img src={discount.icon} /></ChipIcon>
                            <ChipLabel label={`${discount.label} ${discount.value}%`} />
                        </Chip>
                    ))
                ) : (
                    <Chip size="small" style={{ backgroundColor: '#FFF1E4', border: '1px solid #FFD0A5', marginRight: '.5rem', borderRadius: '15px' }}>
                         <ChipIcon><img src={noDiscountIcon} style={{height:'10px', margin: '3px'}}/></ChipIcon>
                        <ChipLabel label="No discount" />
                    </Chip>
                )}
            </div>
        </div>
    );
}

export default CartDiscounts;