import React, { useEffect } from "react";
import classes from '../Style/MarketplaceFilter.module.css';
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import { setLoader, setProducts, setFilters, setProductTypes, setProductSubTypes } from "Store/contentState/marketplaceSlice";
import { resetPaginationsProps } from "Store/contentState/paginationSlice";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import OOT from "Constants/services/oot";
import _ from "lodash";
import { getAccountCurrency } from "../Utils/commons";
import SearchBox from "../common/SearchBox";
import filterIcon from '../../../Assets/Icons/filter.svg';
import indexStyles from '../marketplace/Style/Index.module.css'
import { Label } from '@nokia-csf-uxr/ccfk'
import Toggle from "../common/Toggle";


const MarketplaceFilter = () => {
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
  const dispatch = useDispatch();
  const accountId = marketplaceSelector.accountDetails[0]['id'];
  const accountDetails = marketplaceSelector.accountDetails;
  const [checkToggle, setCheckToggle] = React.useState(true)
  const productPortfolios = [{ "id": "all", "value": "All" }, { "id": "0", "value": "Nokia Portfolio" }, { "id": "1", "value": "Merchant Portfolio" }]
  const productSortBy = [{ "id": "lowhigh", "value": "Price (low to high)" }, { "id": "highlow", "value": "Price (high to low)" }, { "id": "AZ", "value": "Product A to Z " }, { "id": "ZA", "value": "Product Z to A" }]

  // useEffect(() => {
  //   getProductSubTypes();
  // }, [])



  const getProductTypes = async (value) => {
    dispatch(
      setLoader({
        key: "isProductTypeLoading",
        value: true,
      })
    );
    try {
      let currency = getAccountCurrency(accountDetails[0]['currency'], accountDetails[0]['currencyisocode']);
      let filter = `product_data.product_sub_type_id='${value}' and product_data.is_marketplace = 1`
      const productTypesRes = await OOT.getProductTypes(accountId, currency, filter);
      if (productTypesRes.status) {
        const updatedData = productTypesRes.data
          .filter((item) => item.product_id && item.product_id.trim() !== "")
          .map((item) => {
            return {
              "id": item.product_id,
              "value": item.product_id.includes("_") ? item.product_id.replace(/_/g, " ") : item.product_id
            };
          }).sort((a, b) => a.value.localeCompare(b.value));;
        dispatch(
          setLoader({
            key: "isProductTypeLoading",
            value: false,
          })
        );
        dispatch(setProductTypes({
          productTypes: updatedData,
        }))
      } else {
        dispatch(
          setLoader({
            key: "isProductTypeLoading",
            value: false,
          })
        );
      }
    } catch (error) {
      console.error(error)
      dispatch(
        setLoader({
          key: "isProductTypeLoading",
          value: false,
        })
      );
    }
  }

  const getProductSubTypes = async () => {
    dispatch(
      setLoader({
        key: "isProductSubTypeLoading",
        value: true,
      })
    );
    try {
      let filter = `${marketplaceSelector.productQueryFilter}`   //base query to be added here
      let currency = getAccountCurrency(accountDetails[0]['currency'], accountDetails[0]['currencyisocode'])
      const productSubTypesRes = await OOT.getProductSubTypes(accountId, currency, filter);
      if (productSubTypesRes.status) {
        const updatedData = productSubTypesRes.data
          .filter((item) => item.product_sub_type_id !== null && item.product_sub_type_id.trim() !== "")
          .map((item) => {
            return {
              "id": item.product_sub_type_id,
              "value": item.product_sub_type_id.includes("_") ? item.product_sub_type_id.replace(/_/g, " ") : item.product_sub_type_id
            };
          }).sort((a, b) => a.value.localeCompare(b.value));;
        dispatch(
          setLoader({
            key: "isProductSubTypeLoading",
            value: false,
          })
        );
        dispatch(setProductSubTypes({
          productSubTypes: updatedData,
        }))
      } else {
        dispatch(
          setLoader({
            key: "isProductSubTypeLoading",
            value: false,
          })
        );
      }
    } catch (error) {
      console.error(error)
      dispatch(
        setLoader({
          key: "isProductSubTypeLoading",
          value: false,
        })
      );
    }
  }




  const handleProductTypeSelection = (_productType) => {
    if (_productType !== '') {
      dispatch(setFilters({ filterName: 'productType', value: _productType }));
      dispatch(resetPaginationsProps());
    } else {
      dispatch(setFilters({ filterName: 'productType', value: "" }));
      dispatch(resetPaginationsProps());
    }
  }






  const handleSubTypeSelection = (_productSubtype) => {
    if (_productSubtype !== '') {
      dispatch(setFilters({ filterName: 'productSubType', value: _productSubtype }));
      getProductTypes(_productSubtype)
      dispatch(resetPaginationsProps());
    } else {
      dispatch(setFilters({ filterName: 'productSubType', value: "" }));
      dispatch(setProductTypes({
        productTypes: [],
      }))
      dispatch(resetPaginationsProps());
    }
  }


  const handlePortfolioSelection = (_productPortfolio) => {
    if (_productPortfolio !== 'all') {
      dispatch(setFilters({ filterName: 'productPortfolio', value: _productPortfolio }));
      dispatch(resetPaginationsProps());
    } else {
      dispatch(setFilters({ filterName: 'productPortfolio', value: "all" }));
      dispatch(resetPaginationsProps());
    }
  }

  const handleSortbySelection = (_productSortyby) => {
    dispatch(setFilters({ filterName: 'productSortyby', value: _productSortyby }));
    dispatch(resetPaginationsProps());
  }

  const handleToggleSwitch = (check) => {
    setCheckToggle(check)
    dispatch(setFilters({ filterName: 'onlyDeliverable', value: check }));
  }


  return (

    // <div className={classes.marketPlaceFilterContainer}>
    //   <div style={{ display: 'flex', justifyContent: 'flex-start'}}>
    //     {/* <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}><img src={filterIcon} style={{marginBottom:'5px'}}/></div> */}
    //     <div>
    //     <SearchBox placeholder="Search an item"/>
    //     </div>
    //   <div className={classes.verticalDivider} style={{margin:'0 .3rem 0 .3rem'}}></div> 
    //     {/* <div className={classes.filterSelection}>
    //       <SelectBox
    //         // disabled={marketplaceSelector.selectedDeviceID !== '' ? true : false }
    //         placeholder="Product category"
    //         maxWidth={true}
    //         caseInsentiveMatching={true}
    //         selectedItem={marketplaceSelector?.filters?.selection?.productSubType}
    //         data={marketplaceSelector.productSubtypes}
    //         onUpdateSelection={handleSubTypeSelection}
    //         isLoading={marketplaceSelector.isProductSubTypeLoading}

    //       />
    //     </div> */}
    //     {/* <div className={classes.filterSelection}>
    //       <SelectBox
    //         // disabled={marketplaceSelector.selectedDeviceID !== '' ? true : false }
    //         placeholder="Product type"
    //         maxWidth={true}
    //         caseInsentiveMatching={true}
    //         selectedItem={marketplaceSelector?.filters?.selection?.productType}
    //         data={marketplaceSelector.productTypes}
    //         onUpdateSelection={handleProductTypeSelection}
    //         isLoading={marketplaceSelector.isProductTypeLoading}

    //       />
    //     </div> */}

    //     {/* <div className={classes.filterSelection}>
    //       <SelectBox
    //         // disabled={marketplaceSelector.selectedDeviceID !== '' ? true : false }
    //         placeholder="Portfolio Type"
    //         maxWidth={true}
    //         caseInsentiveMatching={true}
    //         selectedItem={marketplaceSelector?.filters?.selection?.productPortfolio}
    //         data={productPortfolios}
    //         onUpdateSelection={handlePortfolioSelection}
    //         isLoading={marketplaceSelector.isProductTypeLoading}

    //       />
    //       </div> */}


    //       <div className={classes.sortbyBlock}>
    //       <div className={classes.verticalDivider} style={{margin:'0rem 1.5rem'}}></div>
    //       <Label className={classes.labelText}>Sort by:</Label>
    //       <div className={classes.filterSelectionSortby}>
    //       <SelectBox
    //         // disabled={marketplaceSelector.selectedDeviceID !== '' ? true : false }
    //         placeholder="Sort By"
    //         maxWidth={true}
    //         caseInsentiveMatching={true}
    //         selectedItem={marketplaceSelector?.filters?.selection?.productSortyby}
    //         data={productSortBy}
    //         onUpdateSelection={handleSortbySelection}
    //         isLoading={marketplaceSelector.isProductTypeLoading}

    //       />
    //       </div>
    //         </div>



    //   </div>



    // </div>
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '.9rem' }}>
        <div style={{ display: 'inline-flex', flex: '0 1 60%' }}>
          <div style={{ width: '100%' }}>
            <SearchBox placeholder="Search an item" />
          </div>
          <div className={classes.verticalDivider} style={{ margin: '0rem 1.5rem' }}></div>
          <div style={{ width: '100%', paddingTop: '5px' }}>
            <Toggle name={"Show only deliverable"} togglecheck={checkToggle}  onUpdateSelection={handleToggleSwitch}/>
          </div>

        </div>




        <div className={classes.sortbyBlock}>
          <div className={classes.verticalDivider} style={{ margin: '0rem 1.5rem' }}></div>
          <Label className={classes.labelText}>Sort by:</Label>
          <div className={classes.filterSelectionSortby}>
            <SelectBox
              // disabled={marketplaceSelector.selectedDeviceID !== '' ? true : false }
              placeholder="Sort By"
              maxWidth={true}
              caseInsentiveMatching={true}
              selectedItem={marketplaceSelector?.filters?.selection?.productSortyby}
              data={productSortBy}
              onUpdateSelection={handleSortbySelection}
              isLoading={marketplaceSelector.isProductTypeLoading} />

          </div>

        </div>
      </div>
      <div style={{ borderBottom: '1px solid #0000001F' }}>

      </div>
    </>

  )
}

export default MarketplaceFilter