import React, { useEffect, useState, useCallback } from "react";

import classes from '../merchant/Style/Merchant.module.css';
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import Loader from "Components/Elements/Loader";
import Card from '@nokia-csf-uxr/ccfk/Card';
import totalorders from '../../Assets/Icons/totalorders.png';
import totalquotation from '../../Assets/Icons/totalquotation.png'
import totalsales from '../../Assets/Icons/totalsales.png';
import trendup from '../../Assets/Icons/trendup.png';
import trenddown from '../../Assets/Icons/trenddown.png';
import SparkLine from "./Graph/SparkLine";
import TotalOrdersSimpleLine from '../../Assets/Icons/TotalOrdersSimpleLine.png';
import TotalquotationSimpleLine from '../../Assets/Icons/TotalquotationSimpleLine.png';
import TotalSalesSimpleLine from '../../Assets/Icons/TotalSalesSimpleLine.png';
import MapLegend from '../../Assets/Icons/MapLegend.png';
import mapimage from '../../Assets/Icons/mapimage.png'
import TabsSection from '../marketplace/common/TabsSection';
import barlink from '../../Assets/Icons/barlink.png'
import ColumnChart from '../../Assets/Icons/ColumnChart.png'
import OOT from "Constants/services/oot";
import { setLoader, setOrders } from "Store/contentState/merchantSlice";
import { useDispatch } from 'react-redux';
import MapContent from "./Graph/MapContent";

const MerchanteBody = () => {
  const dispatch = useDispatch();
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [orderLocation, setOrderLocation] = useState([]);

  const limitPerpage = 50
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoader({ key: "isProductsLoading", value: true }));
      const allData = await fetchAllPages();
      setData(allData);
      dispatch(setLoader({ key: "isProductsLoading", value: false }));
    };

    fetchData();
  }, [])


  if (marketplaceSelector.isPartnerLoading) {
    return <Loader />
  }

  const customTabData = [
    { tab: { children: 'Top 10 Categories', label: 'Tab One description' } },
    { tab: { children: 'Top 10 Products', label: 'Tab Two description' } }
  ];

  const tabContentData = (type) => {
    return (
      <div style={{ paddingTop: '10px' }}>
        <div style={{ paddingTop: '20px' }}>
          <div className={classes.cardheader}>
            <div className={classes.cardheaderleftsection}>
              <div className={classes.carddetails}>
                <div className={classes.carddetailsleftsect}>
                  <img src={barlink} />
                </div>
                <div className="card-details-rightsect">
                  <div className={classes.cardcount}>$ 282,200</div>
                  <div className={classes.cardlabel}>Total revenue</div>
                </div>
              </div>
            </div>
            <div className={classes.cardheaderrightsection}>
              <div className={classes.trend}><img src={trendup} className={classes.trendimg} />3%</div>
              <div className={classes.trendstatus}>Since last week</div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: '20px' }}>
          <img src={ColumnChart} width={"100%"} />
        </div>
      </div>
    )
  }

  let tabContentComponents = [
    tabContentData("categories"),
    tabContentData("products")
  ];

  const handleTabSelect = (index) => {
    tabContentComponents = []
    setActiveTabIndex(index);
  };



  const fetchAllPages = async (page = 0, accumulatedData = []) => {
    const filterConditions = "";
    try {
      const response = await OOT.getAllMerchantOrders(filterConditions, limitPerpage, page);
      const newData = [...accumulatedData, ...response.data.map((a) => a.country_region)];
      if (response.data.length >= limitPerpage) {
        return fetchAllPages(page + response.data.length, newData);
      } else {
        return newData;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return accumulatedData;
    }
  };



  return (
    <div className={classes.marketplaceWrapper}>

      <div className={classes.marketplaceProductsCatalogContainer}>
        <div className={classes.gridContainer}>
          <Card style={{ borderRadius: '10px', display: 'flex', marginRight: '1rem', height: 'fit-content', marginBottom: '1rem', cursor: 'pointer', minHeight: "auto" }} >
            <div className={classes.card}>
              <div className={classes.cardheader}>
                <div className={classes.cardheaderleftsection}>
                  <div className={classes.carddetails}>
                    <div className={classes.carddetailsleftsect}>
                      <img src={totalorders} />
                    </div>
                    <div className="card-details-rightsect">
                      <div className={classes.cardcount}>135</div>
                      <div className={classes.cardlabel}>Total orders</div>
                    </div>
                  </div>
                </div>
                <div className={classes.cardheaderrightsection}>
                  <div className={classes.trend}><img src={trendup} className={classes.trendimg} />5%</div>
                  <div className={classes.trendstatus}>Since last week</div>
                </div>
              </div>
              <div className={classes.cardfooter}>
                <SparkLine stroke={'#9567FE'} />
              </div>
            </div>
          </Card>
          <Card style={{ borderRadius: '10px', display: 'flex', marginRight: '1rem', height: 'fit-content', marginBottom: '1rem', cursor: 'pointer', minHeight: "auto" }} >
            <div className={classes.card}>
              <div className={classes.cardheader}>
                <div className={classes.cardheaderleftsection}>
                  <div className={classes.carddetails}>
                    <div className={classes.carddetailsleftsect}>
                      <img src={totalquotation} />
                    </div>
                    <div className="card-details-rightsect">
                      <div className={classes.cardcount}>278</div>
                      <div className={classes.cardlabel}>Total quotation</div>
                    </div>
                  </div>
                </div>
                <div className={classes.cardheaderrightsection}>
                  <div className={classes.trenddown}><img src={trenddown} className={classes.trendimg} />2%</div>
                  <div className={classes.trendstatus}>Since last week</div>
                </div>
              </div>
              <div className={classes.cardfooter}>
                <SparkLine stroke={'#005AFF'} />
              </div>
            </div>

          </Card>
          <Card style={{ borderRadius: '10px', display: 'flex', marginRight: '1rem', height: 'fit-content', marginBottom: '1rem', cursor: 'pointer', minHeight: "auto" }} >
            <div className={classes.card}>
              <div className={classes.cardheader}>
                <div className={classes.cardheaderleftsection}>
                  <div className={classes.carddetails}>
                    <div className={classes.carddetailsleftsect}>
                      <img src={totalsales} />
                    </div>
                    <div className="card-details-rightsect">
                      <div className={classes.cardcount}>$ 7,12,344</div>
                      <div className={classes.cardlabel}>Total sales</div>
                    </div>
                  </div>
                </div>
                <div className={classes.cardheaderrightsection}>
                  <div className={classes.trend}><img src={trendup} className={classes.trendimg} />3%</div>
                  <div className={classes.trendstatus}>Since last week</div>
                </div>
              </div>
              <div className={classes.cardfooter}>
                <SparkLine stroke={'#00C9FF'} />
              </div>
            </div>

          </Card>

        </div>

        <div className={classes.gridSecondRowContainer}>
          <Card style={{ borderRadius: '10px', display: 'flex', padding: '20px', height: "740px" }} >
            <div className={classes.mapheader}>
              <div className="card-details-rightsect">
                <div className={classes.mapcardcount}>Sales across the world</div>
                <div className={classes.cardlabel}>485 products</div>
              </div>
              <div>
                <img src={MapLegend} />
              </div>
            </div>
            <div style={{ paddingTop: '20px' }}>
              {/* <img src={mapimage} width={'100%'} /> */}
              <MapContent/>
            </div>
           </Card>
           <Card style={{ borderRadius: '10px', display: 'flex', padding: '20px', height: "auto" }} >
            <div style={{marginTop:'20px'}}>
            <div className="card-details-rightsect">
                <div className={classes.mapcardcount}>Order overview</div>
              </div>
            </div>
          </Card>
        </div>

      </div>


    </div>
  )
}

export default MerchanteBody