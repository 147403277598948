import { createSlice } from "@reduxjs/toolkit";
import { setProp } from "Store/Actions";

const initialData = {
    paginationProps: {
        currentPage: 1,
        offset: 0,
        limitPerpage: 20,
        totalPages: 0
    },
}

const paginationSlice = createSlice({
    name: 'pagination',
    initialState: initialData,
    reducers: {
        setCurrentPage(state, action) {
            state.paginationProps.currentPage = action.payload;
          },
          setOffset(state, action) {
            state.paginationProps.offset = action.payload;
          },
          setLimit(state, action) {
            state.paginationProps.limitPerpage = action.payload;
          },
          resetPaginationsProps(state) {
            state.paginationProps = initialData.paginationProps;
          }
    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: {
            payload: any;
            type: string;
            slice: string;
        }) => {
            if (action.payload.slice === paginationSlice.name) {
                state[action.payload.key] = action.payload.value;
            }
        });
    }
})

export default paginationSlice.reducer; 
export const {setCurrentPage, setOffset, setLimit, resetPaginationsProps} = paginationSlice.actions;
