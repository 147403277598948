import React, { useState, useEffect } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import Label from '@nokia-csf-uxr/ccfk/Label';
import { setProp } from "Store/Actions";
import userStyles from "../Style/UserManagement.module.css";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import { modifyData, getRequestedInfo } from "../utils/constants"
import { updateSelectedPersonas, setUserNSA, setUserInputFields } from "Store/contentState/userManagementSlice";
import Checkbox, {
    CheckboxLabelContent,
} from '@nokia-csf-uxr/ccfk/Checkbox';
import { setOotRoles } from "Store/contentState/userManagementSlice";



const CommonOrganizationType = (prop) => {
    const isAddingNokiaUser = useSelector((state: RootState) => state.userManagement.isAddingNokiaUser);
    const isEmailEntered = useSelector((state: RootState) => state.userManagement.isEmailEntered);
    const userOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
    const organizationTypes = useSelector((state: RootState) => state.userManagement.metaData.persona);
    const modifiedOrganizationTypes = modifyData(organizationTypes, 'id', 'name');
    const filteredExternalTypes = modifiedOrganizationTypes.filter((type) => type.id !== "nokia");
    const allAccountsForExternal = useSelector((state: RootState) => state.userManagement.allAccountsForExternal);
    const auth = useSelector((state: RootState) => state.authSlice.login.adToken.accessToken)
    const email = useSelector((state: RootState) => state.userManagement.userData.emailAddress);
    const nsa_authorized = useSelector((state: RootState) => state.userManagement.userData.nsa_authorized);
    const dispatch = useDispatch();
    const [checkedValue, setcheckedValue] = useState(nsa_authorized);
    const [checkdisable, setcheckdisable] = useState(false);


    const handleOrgTypeSelection = (orgType) => {
        dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: [] }));
        dispatch(setOotRoles({ type: "roles", value: [] }));
        dispatch(setOotRoles({ type: "approver_role_group", value: [] }));
        dispatch(setUserInputFields({ field: 'supplier_account_id', value: [] }));
        if (orgType) {
            console.log(orgType, 'orgType')
            dispatch(updateSelectedPersonas([orgType]))
        } else {
            dispatch(updateSelectedPersonas([]))
        }

    }

    useEffect(() => {

        if (userOrgType && userOrgType.length > 0 && userOrgType[0] == "nokia") {
            fetchNSACheck(email);
            setcheckdisable(true)
        }
        else {
            setcheckdisable(false)
        }

    }, [isAddingNokiaUser]);



    const fetchNSACheck = async (email) => {
        const headers = new Headers();
        const bearer = `Bearer ${auth}`;
        let mailId = "bharath.r@nokia.com"
        headers.append("Authorization", bearer);
        headers.append("ConsistencyLevel", "eventual")
        const options = {
            method: "GET",
            headers: headers
        };
        let resp = await fetch(`https://graph.microsoft.com/v1.0//groups/2557f067-cbb8-4e30-8cc7-6f6733926866/transitiveMembers?$select=id,displayName,userType,appId,mail,onPremisesSyncEnabled,deviceId&$search="mail:${email}"`, options)
            .then(response => response.json())
            .catch(error => console.log(error));
        if (resp?.value?.length > 0) {
            setcheckedValue(true)
            dispatch(setUserInputFields({ field: 'nsa_authorized', value: true}));
        }
        else {
            setcheckedValue(false)
            dispatch(setUserInputFields({ field: 'nsa_authorized', value: false}));
        }
    };

    const handleDataElementsCheckboxChange = (event) => {
        setcheckedValue(event.target.checked)
        dispatch(setUserNSA(event.target.checked))
    }

   

    return (
        <>
            <div className={userStyles.gridItemNSA}>
                {
                    ((isEmailEntered && isAddingNokiaUser) || (prop.mode === "edit" && userOrgType[0] === "nokia")) ? (
                        <div className={`${userStyles.marginBottomWidth}`}>
                            <Label className={userStyles.userLabel}>Organization type <span style={{ color: '#C91514' }}>*</span></Label>
                            <TextInput
                                id="organization-type"
                                disabled={true}
                                value={modifiedOrganizationTypes.find((type) => type.id === "nokia").value}
                                variant={"outlined"}
                                placeholder=""
                            />
                        </div>
                    ) : ((isEmailEntered && !isAddingNokiaUser) || (prop.mode === "edit" && userOrgType[0] != "nokia")) && (<div className={`${userStyles.marginBottomWidth}`}>
                        <Label className={userStyles.userLabel}>Organization type <span style={{ color: '#C91514' }}>*</span></Label>
                        <SelectBox
                            placeholder="Select"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={userOrgType[0]}
                            data={filteredExternalTypes}
                            onUpdateSelection={handleOrgTypeSelection}
                            isLoading={false}
                        />
                    </div>)


                }{email && userOrgType[0] === "nokia" && (<div style={{ paddingLeft: '20px' }}>
                    <Label className={userStyles.userLabel}>NSA</Label>
                    <Checkbox
                        checked={checkedValue}
                        onChange={(event) =>
                            handleDataElementsCheckboxChange(event)
                        }
                        disabled={checkdisable}
                    />
                </div>)}</div>
        </>
    )
}

export default CommonOrganizationType;