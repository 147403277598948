import React, { useState, useRef, useEffect, useCallback } from "react";
import _isEmpty from "lodash/isEmpty";
import _toLower from "lodash/toLower";
import PropTypes from "prop-types";
 
import Label from "@nokia-csf-uxr/ccfk/Label";
import SelectItem, {
    SelectItemLabelContent,
    SelectListItem,
    SelectItemInput,
    SelectItemText,
    SelectItemClearButton,
    SelectItemProgressIndicator,
} from "@nokia-csf-uxr/ccfk/SelectItem";
import { FixedSizeList as VirtualizedList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import styled, { css } from 'styled-components';
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
 
 
const ITEMS_IN_LIST = 5000;
const ROW_HEIGHT = 32;
const ONE_REM = 0.0625;
const SERVER_TIMEOUT_VALUE = 500;
const TRANSITION_BEZIER = 'cubic-bezier(0.4, 0, 0.2, 1)';
const TRANSITION_TIME = '.1s';
 
const ListStyling = styled.div`
  ${props => css`
    width: 100%;
    height: 100%;
    transition: height ${TRANSITION_TIME} ${TRANSITION_BEZIER};
  `}
`;
 
const isSelectionKeyPressed = true; //key => key && (key === ENTER_KEY || key === SPACE_KEY);
 
// case insensitive matching all occurrences
const isMatched = (item, searchPattern) => {
    const itemIsIncluded =
        _toLower(item.value).indexOf(_toLower(searchPattern)) >= 0;
    return _isEmpty(searchPattern) || itemIsIncluded;
};
 
// exact matching
const isMatchedExactly = (item, searchPattern) => {
    const itemIsIncluded = item && item.value.indexOf(searchPattern) >= 0;
    return _isEmpty(searchPattern) || itemIsIncluded;
};
 
const ESCAPE = "Escape";
const TAB = "Tab";
 
const SelectBoxWithCustomSearch = (props) => {
    const {
        labelText,
        disabled,
        required,
        maxWidth,
        error,
        errorMessage,
        data,
        caseInsentiveMatching,
        placeholder,
        creatable,
        truncateListText,
        elevationIndex,
        selectedItem,
        onUpdateSelection,
        isLoading,
        isClearable,
        fetchRecords,
        isInfityscroll,
        operationType,
        loadDataList,
        selectedItemsList
 
    } = props;
 
    // console.log(data, 'prod data')
    const selectItemRef = useRef(null);
    const selectInputRef = useRef(null);
    const [newSearch, setNewSearch] = useState(false);
    const [inputText, setInputText] = useState("");
    const [selected, setSelected] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [shouldMarkItems, setShouldMarkItems] = useState(false);
    const [dataOptions, setDataOptions] = useState(data); // list of ALL options
    const [selectItems, setSelectItems] = useState(data); // filtered options
 
    const allAccountsForExternal = useSelector((state: RootState) => state.userManagement.allAccountsForExternal);

    const [selectItemValue, setselectItemValue] = useState(true); // filtered options
 
    const selectItemListRef = useRef(null);
 
    const stopEvents = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
 
 
 
    const haveSelectedItems = () => selectItems && selectItems.length > 0;
 
 
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    const [listMaxHeight, setListMaxHeight] = useState(null);
 
    const itemCount = hasNextPage ? dataOptions.length + 1 : dataOptions.length;
 
    const virtualizedListRef = useRef(null);
    const selectItemBaseRef = useRef(null);

    let serachtext = false
 
    useEffect(() => {
        setDataOptions(data);
        setSelectItems(data);
        if (data.length === 0 && selectedItem == "") {
            setSelected("");
            setInputText("");
        } else {
            const item = data.filter((row) => row.id === selectedItemsList?.id);
            if (item.length > 0) {
                setselectItemValue(false)
                setSelected(item[0]);
                setInputText(item[0]?.value ?? "");
            }
        }
        
       // updateSelection(selectedItemsList)
    }, [data, data.length]);
 
 
    const loadMoreItems = isNextPageLoading
        ? () => {
          
        }
        : () => {
            setIsNextPageLoading(true);
            setTimeout(() => {
                fetchRecords(selectedItemsList ? '' : selected ? '' : inputText)
                setIsNextPageLoading(false);
                setHasNextPage(dataOptions.length < ITEMS_IN_LIST);
            }, SERVER_TIMEOUT_VALUE);
        };
 
    const isItemLoaded = (index) => !hasNextPage || index < dataOptions.length;
 
    const getListHeight = useCallback(() => {
        const windowHeight = window.innerHeight;
        const selectItemHeight =
            selectItemBaseRef.current?.clientHeight || 0;
        const maxHeight = Math.floor(windowHeight * 0.5);
        setListMaxHeight(maxHeight - selectItemHeight / 2 + 8);
    }, []);
 
 
 
 
    useEffect(() => {
        window.addEventListener('resize', getListHeight);
        getListHeight();
        return () => window.removeEventListener('resize', getListHeight);
    }, [getListHeight]);
 
 
 
 
 
    const updateSelection = (item) => {
        setSelected(item);
        if (item.value) {
            setInputText(item.value);
        } else {
            setInputText("");
        }
        setIsOpen(false);
        onUpdateSelection(item.id ?? "");
    };
 
    const shouldUpdateSelection = (event) => {
        const { key, type } = event;
        // if ESCAPE or TAB or click (outside) event closed the menu keep current value of `selected`
        if (key === ESCAPE || key === TAB || type === "mousedown") {
            updateSelection(selected);
        }
    };
 
    // on SelectListItem, handle Menu item selection and close dropdown Menu after item is selected
    const handleEvent = (item) => (event) => {
        const { type, key } = event;
        switch (type) {
            case "keydown":
                if (isSelectionKeyPressed) {
                    updateSelection(item);
                }
                break;
            case "click":
                updateSelection(item);
                break;
            default:
        }
    };
 
    // return any selectitems containing `filterValue`.
    const getFilteredValues = (filterValue) => {
        let filteredItems;
        if (caseInsentiveMatching) {
            filteredItems = dataOptions.filter((item) =>
                isMatched(item, filterValue)
            );
        } else {
            filteredItems = dataOptions.filter((item) =>
                isMatchedExactly(item, filterValue)
            );
        }
        // if(filteredItems.length == 0){
        //   getSerachtDataList(filterValue)
        // }
        // else{
        // return filteredItems;
        // }
        return filteredItems;
    };
 
    // const handleInputChange = (event) => {
    //     setIsNextPageLoading(false);
    //     let newText = event.target.value;
    //     if (newSearch) {
    //         // remove the unnecessary text
    //         newText = newText.replace(inputText, "");
    //     }
    //     setNewSearch(false);
    //     if (newText === "") {
    //         // space entered to open the List
    //         newText = "";
    //         setSelectItems(dataOptions); // use entire list for new searches
    //         setDataOptions(allAccountsForExternal)
    //     } else {
    //         setSelectItems(getFilteredValues(newText));
    //         setShouldMarkItems(true);
 
    //         setDataOptions(selectItems)
    //     }
    //     setInputText(newText);
 
    //     setIsOpen(true);
    // };

    const handleInputChange = (event) => {
        setIsNextPageLoading(false);
        let newText = event.target.value;
        const filtered = allAccountsForExternal.filter(item =>
            item.value.toLowerCase().includes(newText.toLowerCase())
          );
        
        setShouldMarkItems(true);
        setDataOptions(filtered)
        setInputText(newText);
 
        setIsOpen(true);
    };
 
    const handleInputSubmit = (event) => {
        if (!haveSelectedItems()) {
            // getSerachtDataList()
            // no item to select, leave menu open
            setIsOpen(true);
            return;
        }
        if (inputText === "") {
            // if inputText is empty, keep latest selected value
            //setInputText(selected.value);
            setSelected(selected);
        } else if (inputText !== selected) {
            // select the 1st item from the filtered list
            setInputText(selectItems[0]);
            setSelected(selectItems[0]);
        }
        setIsOpen(false);
 
 
 
    };
 
 
    const Row = ({ index, style }) => {
        const isLoaded = isItemLoaded(index);
        //console.log(isLoaded, dataOptions)

        // if (loadDataList === false) {
        //     return (
        //         <div style={style}>
        //             <SelectItemText>{""}</SelectItemText>
        //         </div>
        //     );
        // }
        
        return (
            <SelectListItem
                role="option"
                style={style}
                selected={selected === dataOptions[index]}
                key={index}
                onClick={handleEvent(dataOptions[index])}
                onKeyDown={handleEvent(dataOptions[index])}
            >
                {isLoaded ? <SelectItemText>{dataOptions[index].value}</SelectItemText> : loadDataList ? <SelectItemProgressIndicator
                        style={{ height: `4rem`, marginLeft: "0.75rem" }}
                        size="2rem"
                        //contentStyle={{marginLeft: '-0.75rem'}}
                        circularIndicatorProps={{ strokeWidth: 4 }}
                    /> : ""}
            </SelectListItem>
        );
    };
 
 
    const handleInputKeyDown = (event) => {
        const { key } = event;
        if (key === TAB) {
            // prevent tabbing off SelectItem when not done selecting (e.g. no matching items in search)
            if (!haveSelectedItems()) {
                stopEvents(event);
            } else {
                const isTypedTextInData = dataOptions.includes(inputText);
                // prevent tabbing when the search text entered is not in the list
                if (!isTypedTextInData && inputText.length > 0) {
                    stopEvents(event);
                } else {
                    // if user pressed TAB w/o entering data, keep the original selected data
                    if (inputText.length > 0) {
                        setSelected(inputText);
                    } else {
                        setInputText(selected);
                    }
                    setIsOpen(false);
                }
            }
        }
    };
 
    const renderClearButton = (props) => {
        return (
            <SelectItemClearButton
                aria-label="clear input"
                onClick={() => {
                    updateSelection("");
                    selectInputRef.current && selectInputRef.current.focus();
                }}
                {...props}
            />
        );
    };
 
    // searchable base component
    const renderSelectItemInput = (props) => {
        // always show ClearButton when there is a value selected
        const showClearButton = isClearable && (!disabled && selected !== "");
        return (
            <SelectItemInput
                ref={selectInputRef}
                autoComplete="off"
                placeholder={inputText !== "" ? undefined : placeholder}
                maxWidth={maxWidth}
                onChange={handleInputChange}
                onSubmit={handleInputSubmit}
                onFocus={() => {
                    setNewSearch(true);
                }}
                onKeyDown={handleInputKeyDown}
                value={inputText}
                role="combobox"
                {...props}
                renderClearButton={showClearButton ? renderClearButton : undefined}
            />
        );
    };
 
    const cssWidth = props?.boxWidth ?? "100%";
 
 
    return (
        <div style={{ width: '100%'}}>
            {labelText && labelText.length > 0 && (
                <Label
                    htmlFor="selectitem-component-input"
                    variant="vertical-layout"
                    maxWidth={maxWidth}
                >
                    <SelectItemLabelContent variant={required ? "required" : "default"}>
                        {labelText}
                    </SelectItemLabelContent>
                </Label>
            )}
 
            {/* <VariantProvider componentName="SelectItem" knobTitle="SelectItem variant"> */}
            <SelectItem
                
                aria-label={inputText !== "" ? undefined : placeholder}
                renderSelectItemBase={renderSelectItemInput}
                disabled={disabled}
                searchable
                creatable={creatable}
                truncateListText={truncateListText}
                maxWidth={maxWidth}
                isOpen={isOpen}
                onOpen={() => {
                    setSelectItems(shouldMarkItems ? selectItems : dataOptions);
                    setIsOpen(true);
                    setShouldMarkItems(false);
                }}
                onClose={(event) => {
                    if (haveSelectedItems()) {
                        setIsOpen(false);
                    }
                    shouldUpdateSelection(event);
                }}
                error={error}
                errorMessage={errorMessage}
                listProps={{
                    ulProps: { role: "listbox" },
                    elevationProps: {
                        elevationIndex: elevationIndex,
                    },
                    style:{minHeight: 'unset', maxHeight: 'unset'},
                    ref: selectItemListRef
                }}
            >
                {isLoading && (
                    <SelectItemProgressIndicator
                        style={{ height: `4rem`, marginLeft: "0.75rem" }}
                        size="2rem"
                        //contentStyle={{marginLeft: '-0.75rem'}}
                        circularIndicatorProps={{ strokeWidth: 4 }}
                    />
                )}
 
                <ListStyling >
                    <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={itemCount}
                        loadMoreItems={loadMoreItems}
                        ref={virtualizedListRef}
                    >
                        {({ onItemsRendered, ref }) => (
                            <VirtualizedList
                                height={listMaxHeight}
                                itemCount={itemCount}
                                itemSize={ROW_HEIGHT}
                                onItemsRendered={onItemsRendered}
                                ref={ref}
                            >
                                {Row}
                            </VirtualizedList>
                        )}
                    </InfiniteLoader>
                </ListStyling>
                {!creatable && !haveSelectedItems() && !isLoading && (
                    <SelectListItem disabled>No matching items</SelectListItem>
                )}
            </SelectItem>
        </div>
    );
};
 
SelectBoxWithCustomSearch.propTypes = {
    labelText: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    maxWidth: PropTypes.bool,
    boxWidth: PropTypes.string,
    placeholder: PropTypes.string,
    data: PropTypes.array,
    searchabale: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    caseInsentiveMatching: PropTypes.bool,
    creatable: PropTypes.bool,
    addAdditionLabel: PropTypes.string,
    truncateListText: PropTypes.bool,
    elevationIndex: PropTypes.number,
    selectedItem: PropTypes.string,
    onUpdateSelection: PropTypes.func,
    isLoading: PropTypes.bool,
    isClearable: PropTypes.bool,
    fetchRecords: PropTypes.func,
    isInfityscroll: PropTypes.bool,
    minimumBatchSize: PropTypes.number,
    isLegacy: PropTypes.bool,
    index: PropTypes.number,
    style: PropTypes.object,
    operationType: PropTypes.string,
    loadDataList: PropTypes.bool,
    selectedItemsList: PropTypes.object
    // setLoading:PropTypes.func
};
 
SelectBoxWithCustomSearch.defaultProps = {
    labelText: undefined,
    disabled: false,
    required: false,
    maxWidth: false,
    boxWidth: "100%",
    placeholder: undefined,
    error: false,
    errorMessage: undefined,
    data: [],
    caseInsentiveMatching: false,
    creatable: false,
    addAdditionLabel: undefined,
    truncateListText: false,
    elevationIndex: 8,
    selectedItem: "",
    isLoading: false,
    isClearable: true,
};
 
export default SelectBoxWithCustomSearch;