import { createSlice } from "@reduxjs/toolkit";
import { setProp } from "Store/Actions";

 let initialState = {
    sideBarOpen: false,
    globalSearchText: "",
    localSearchText: ""
 }

 const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setGlobalSearchText: (state, action) => {
            state.globalSearchText = action.payload;
        },
        clearGlobalSearchText: (state) => {
            state.globalSearchText = '';  // Clear global search text
        }
    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: {
            payload: any;
            type: string;
            slice: string;
        }) => {
            if (action.payload.slice === commonSlice.name) {
                state[action.payload.key] = action.payload.value;
            }
        });
    }
});



 export default commonSlice.reducer;
 export const { setGlobalSearchText, clearGlobalSearchText } = commonSlice.actions;