import React from 'react'
import Card from '@nokia-csf-uxr/ccfk/Card';
import Button from '@nokia-csf-uxr/ccfk/Button';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import marketplaceOrderIcon from '../../Assets/Icons/marketplace_order_icon.svg';
import classes from '../marketplace/Style/ProductCard.module.css';
import { useNavigate } from 'react-router-dom';
import { Hyperlink, Typography } from "@nokia-csf-uxr/ccfk";
import nokiaFlatLogo from '../../Assets/Icons/nokia-flat-logo.svg';
import sellerLogo from '../../Assets/Icons/seller-logo.svg';
import shipIcon from '../../Assets/Icons/shipping_icon.png'
import Chip, {
  ChipLabel,
} from '@nokia-csf-uxr/ccfk/Chip';
import productDefaultImage from '../../Assets/Icons/defProdImage.png';
import useImageRender from './hooks/useImageRender';
import { getSupplierImage, getFulFillmentType } from './Utils/commons';
import { useDispatch } from 'react-redux';
import { setSelectedDeviceSpecification } from 'Store/contentState/marketplaceSlice';
import { formatNumberWithCommas } from '../marketplace/Utils/commons';



const ProductCard = (prop) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formattedPrice = formatNumberWithCommas(prop.card.price_amount)
  // const productId = prop.card.product_id ? prop.card.product_id.replace('_', ' ') : 'Product';
  const imageSource = getSupplierImage(prop.card.is_agent_model, prop.card.supplier_text)
  const [productImageSrc, handleImageError] = useImageRender(
    prop.card.product_image,
    prop.card.image_url,
    productDefaultImage,

  );

  const handleAddToCart = () => {
    prop.onAddToCart(prop.card);
  };
  const handleCardClick = (event) => {
    // Prevent default behavior to avoid navigation on card click
    event.preventDefault();
    const isButtonClicked = event.target.outerText === 'Add to cart';
    if (!isButtonClicked) {
      dispatch(setSelectedDeviceSpecification({ selectedProduct: [prop.card] }))
      navigate(`product/${prop.card.id}`);
    }
  };

  const getImageHeight = () => {
    if (prop.card.supplier_text.toLowerCase().includes('etra')) {
      return true;
    } else {
      return false;
    }
  }




  return (
    //initially width was 300
    // <Card style={{ borderRadius: '10px', display: 'flex', marginRight: '1rem', height: 'fit-content', marginBottom: '1rem', cursor: 'pointer', minHeight: "auto" }} onClick={(event) => handleCardClick(event)}>
    //   <div className={classes.marketplaceProductCardBody}>
    //     {/* <div className={`${classes.marketplaceProductCardName} ${classes.marginBottom}`}>
    //                     <img src={nokiaFlatLogo} alt="Icon" />
    //                     <Typography style={{ marginLeft: '.2rem', marginTop: '1.67em', marginBottom:'1.67em', fontSize: '14px', color:'#000', fontWeight: 700}}>{productId}</Typography>
    //                     <Chip size="small" style={{backgroundColor:'#fff', border:'1px solid #000', borderRadius:'15px'}}><ChipLabel label={prop.card.hasOwnProperty('price_amount') ? `${prop.card.price_amount} ${prop.card.priceamount_currencycode}` : "100 USD"} /></Chip>
    //                 </div> */}

    //     <div className={`${classes.marketplaceProductCardName} ${classes.marginBottom}`}>
    //       <div style={{ minHeight: "auto" }}>
    //         <img src={imageSource} alt="Icon" className={(getImageHeight() === true) ? classes.etraTelecomLogo : classes.productSupplierLogo} />
    //         <Typography style={{ fontSize: '14px', color: '#777777', fontWeight: 700 }}>{prop.card.id}</Typography>
    //       </div>
    //       <Chip size="small" style={{ backgroundColor: '#fff', border: '1px solid #000', borderRadius: '15px' }}><ChipLabel label={prop.card.hasOwnProperty('price_amount') ? `${formattedPrice} ${prop.card.priceamount_currencycode}` : ""} /></Chip>
    //     </div>

    //     <div style={{ display: 'flex', justifyContent: 'space-between' }} className={classes.marginBottom}>
    //       <Typography style={{ fontSize: '14px', color: '#757575', fontWeight: 400, marginTop: '10px', marginBottom: '10px' }}>{prop.card.product_description}</Typography>
    //       <img
    //         src={productImageSrc}
    //         onError={() => handleImageError()}
    //         style={{ height: '50px', objectFit: "contain", maxWidth: "100%" }} />
    //     </div>

    //     {getFulFillmentType(prop.card) && (  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '15px' }}>
    //       <img src={shipIcon} alt='ship_icon' />

    //         <Typography style={{ fontSize: '12px', color: '#757575', fontWeight: 'bold', marginLeft: '2px' }}>
    //           Ships from: <span style={{ fontWeight: 'normal' }}>{getFulFillmentType(prop.card)}</span>
    //         </Typography>

    //     </div> )}


    //     {/* <div style={{ display: 'flex', justifyContent: 'center' }} className={classes.marginBottom}>


    //       <div className={classes.mlAuto}>


    //         <Button

    //           variant="secondary-small"
    //           // onClick={() =>  navigate(`/product/${prop.card.id}`)}
    //           onClick={handleAddToCart}
    //         >
    //           <ButtonIcon><img src={marketplaceOrderIcon} /></ButtonIcon>
    //           <ButtonText>Add to cart</ButtonText>

    //         </Button>
    //       </div>


    //     </div> */}

    //     <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
    //       <div style={{ position: 'absolute', right: '17px', bottom: '5px' }} className={classes.marginBottom}>
    //         <div className={classes.mlAuto}>
    //           <Button
    //             variant="secondary-small"
    //             onClick={handleAddToCart}
    //           >
    //             <ButtonIcon><img src={marketplaceOrderIcon} alt="order-icon" /></ButtonIcon>
    //             <ButtonText>Add to cart</ButtonText>
    //           </Button>
    //         </div>
    //       </div>
    //     </div>

    //   </div>


    // </Card>

    <Card style={{ borderRadius: '8px', display: 'flex', marginRight: '1rem', height: 'fit-content', marginBottom: '1rem', cursor: 'pointer', minHeight: "auto", border: '1px solid #DDDDDD' }} onClick={(event) => handleCardClick(event)}>
      <div>
        <div style={{ height: '216px', backgroundImage: "linear-gradient(to left, #E1EFFE40, #96AAC540)" }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem' }}>
            <div style={{ minHeight: "auto" }}>
              <img src={imageSource} alt="Icon" className={(getImageHeight() === true) ? classes.etraTelecomLogo : classes.productSupplierLogo} />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}>
            <img
              src={productImageSrc}
              onError={() => handleImageError()}
              style={{ height: '100px', objectFit: "contain", maxWidth: "100%" }} />
          </div>
        </div>
        <div style={{ padding: '1rem' }}>
          <div className={`${classes.marketplaceProductCardName} ${classes.marginBottom}`}>
            <div style={{ minHeight: "auto" }}>
              {/* <img src={imageSource} alt="Icon" className={(getImageHeight() === true) ? classes.etraTelecomLogo : classes.productSupplierLogo} /> */}
              <Typography style={{ fontSize: '14px', color: '#777777', fontWeight: 700 }}>{prop.card.id}</Typography>
            </div>
            <Chip size="small" style={{ backgroundColor: '#fff', border: '1px solid #000', borderRadius: '15px' }}><ChipLabel label={prop.card.hasOwnProperty('price_amount') ? `${formattedPrice} ${prop.card.priceamount_currencycode}` : ""} /></Chip>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Typography style={{ fontSize: '14px', color: '#212121', fontWeight: 700, marginTop: '10px', marginBottom: '10px' }}>{prop.card.product_description}</Typography>
          </div>

          {getFulFillmentType(prop.card) && (<div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '15px' }}>
            <img src={shipIcon} alt='ship_icon' />
            <Typography style={{ fontSize: '12px', color: '#757575', fontWeight: 'bold', marginLeft: '2px' }}>
              Ships from: <span style={{ fontWeight: 'normal' }}>{getFulFillmentType(prop.card)}</span>
            </Typography>

          </div>)}

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginTop:' 50px' }}>
            <div style={{ position: 'absolute', right: '17px', bottom: '5px' }} className={classes.marginBottom}>
              <div className={classes.mlAuto}>
                <Button
                  variant="secondary-small"
                  onClick={handleAddToCart}
                >
                  <ButtonIcon><img src={marketplaceOrderIcon} alt="order-icon" /></ButtonIcon>
                  <ButtonText>Add to cart</ButtonText>
                </Button>
              </div>
            </div>
          </div>


        </div>
      </div>
    </Card>


  )

}

export default ProductCard;