import React, { useState } from 'react';
import ToggleIcon from '@nokia-csf-uxr/ccfk-assets/latest/ToggleIcon';
import ToggleDisabledIcon from '@nokia-csf-uxr/ccfk-assets/latest/ToggleDisabledIcon';
import ToggleSwitch, { ToggleSwitchLabelContent } from '@nokia-csf-uxr/ccfk/ToggleSwitch';
import Label from '@nokia-csf-uxr/ccfk/Label';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';

const Toggle = (prop) => {
  const [checked, setChecked] = useState(prop.togglecheck);
  const handleChange = (e) => {
    setChecked(e.value);
    prop.onUpdateSelection(e.value)
  };
  return (
    <Label>
       <Tooltip
        placement="top"
        trigger="hover"
        tooltip={checked ? 'Power On' : 'Power Off'}
        closeOnReferenceHidden={false}
        modifiers={
          [{ name: 'offset', options: { offset: [0, 10] } }]
        }
      >
      <ToggleSwitch
        variant="plain"
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': '', type: 'button', role: 'switch', 'aria-checked': checked }}
        toggleIcon={checked ? <ToggleIcon /> : <ToggleDisabledIcon />}
      />
      </Tooltip>
      <ToggleSwitchLabelContent>{prop.name}</ToggleSwitchLabelContent>
    </Label>
  );
};

export default Toggle;