import React, { useEffect } from "react";

import classes from '../marketplace/Style/Marketplace.module.css'
import MarketplaceFilter from './Components/MarketplaceFilter';
import ProductsCatalogPage from './ProductsCatalogPage';
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import Loader from "Components/Elements/Loader";
import CommonFilter from "./common/CommonFilter";
import FilterWrapper from "./Components/Filters/FilterWrapper";
import catalogueStyles from './Style/ProductCatalogueBody.module.css';
import CatalogueLandingPage from "./Components/CatalogueLandingPage";


const MarketplaceBody = () => {
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);

  if (marketplaceSelector.isPartnerLoading) {
    return <Loader />
  }
  return (
    // <div className={classes.marketplaceWrapper}>
    //   {marketplaceSelector.isGetProducts === false && <CommonFilter />}
    //   {
    //     marketplaceSelector.isGetProducts && <MarketplaceFilter />
    //   }

    //   <div className={catalogueStyles.catalogueBody}>
    //     {marketplaceSelector.isGetProducts && <FilterWrapper />}

    //     <div className={catalogueStyles.rightCatalogueSection}>
    //       <ProductsCatalogPage />
    //     </div>
    //   </div>
    // </div>

    <div className={classes.marketplaceWrapper}>
      {marketplaceSelector.isGetProducts === false && <CommonFilter />}
      <CatalogueLandingPage />
      {marketplaceSelector.isGetProducts && (
        <div className={catalogueStyles.catalogueBody}>
          <div className={catalogueStyles.leftFilterSection}><FilterWrapper /></div>
          <div className={catalogueStyles.rightCatalogueSection}>
            <MarketplaceFilter />
            <ProductsCatalogPage />
          </div>

        </div>
      )
      }
    </div>
  )
}

export default MarketplaceBody