import React, { useEffect, useState } from "react";
import OverlayPanel from '@nokia-csf-uxr/ccfk/OverlayPanel';
import exp from "constants";
import {
    OverlayPanelHeader
} from '@nokia-csf-uxr/ccfk/OverlayPanel';
import HorizontalDivider from '@nokia-csf-uxr/ccfk/HorizontalDivider';
import Typography from '@nokia-csf-uxr/ccfk/Typography';
import ButtonsRow from '@nokia-csf-uxr/ccfk/ButtonsRow';
import TOKENS from '@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6';
import ToggleButton from '@nokia-csf-uxr/ccfk/ToggleButton';
import IconButton from '@nokia-csf-uxr/ccfk/IconButton';
import CloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import MoreVerticalIcon from '@nokia-csf-uxr/ccfk-assets/latest/MoreVerticalIcon';
import InfoOutlineIcon from '@nokia-csf-uxr/ccfk-assets/latest/InfoCircleIcon';
import userStyles from '../Style/UserManagement.module.css';
import { ElevationProps } from "@nokia-csf-uxr/ccfk/Elevation";
import OverlayToggleIcon from "../../../../Assets/Icons/overlay-panel-icon.svg";
import Button from '@nokia-csf-uxr/ccfk/Button';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";


const CONTENT_STYLE = {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
};

const SCROLLABLE_CONTENT = {
    maxHeight: '700px',  // Set a fixed max height for the scrollable area
    overflowY: 'auto',   // Enable vertical scrolling
    padding: '2rem',     // Optional: Add padding for better content spacing
};



type IDUserDetailsOverlayProp = {
    onClose: () => void;
    selectedUserData: any;

};



const UserDetailsOverlay = ({ onClose, selectedUserData }: IDUserDetailsOverlayProp) => {
    const levels = Object.keys(selectedUserData.markets);
    const hierarchyLevel4 = useSelector((state: RootState) => state.userManagement.hierarchyLevel4Data);
    const accountInfo = selectedUserData.business_name;
    const rolesList = useSelector((state: RootState) => state.userManagement.roleInfo.rolesData);
    // const viewInfo = selectedUserData.viewAccess;

    const [marketNames, setMarketNames] = useState([]);
    const [viewInfo, setViewInfo] = useState([]);


    const renderButtons = () => (
        <>
            <IconButton onClick={onClose} aria-label="close">
                <CloseIcon />
            </IconButton>
            {/* <img src={OverlayToggleIcon} onClick={onClose} style={{ cursor: 'pointer' }} /> */}


        </>
    );

    useEffect(() => {
        getFilteredData();
        getViewInfo();
    }, [])

    const getFilteredData = () => {
        const filteredData = hierarchyLevel4.filter((item) =>
            selectedUserData.markets?.level4?.includes(item.id)
        );

        // Map the filtered data to extract levels
        const data = filteredData.map((item) => item.level);
        setMarketNames(data);
    }

    const getViewInfo = () => {
        const viewData = rolesList.filter((item) => selectedUserData?.userRoles?.includes(item.id));
        setViewInfo(viewData[0]?.data_objects?.view);
    }


    return (

        <>
            <OverlayPanel
                elevationProps={{ elevationIndex: 6 }}
                visible={true}
                style={{ minWidth: '20rem' }}
                className={userStyles.userOverleay}
            >
                <OverlayPanelHeader
                    title={selectedUserData.name}
                    buttons={renderButtons()}
                    typographyProps={{ tabIndex: 0 }}
                />
                <HorizontalDivider />

                {/* @ts-ignore */}
                <div style={SCROLLABLE_CONTENT}>

                    <div className={userStyles.marginBottom}>
                        <Typography className={userStyles.userInfoLabel}>Markets</Typography>
                        {/* {levels.some((level) => Array.isArray(selectedUserData.markets[level]) && selectedUserData.markets[level].length > 0) ? (
                            // Display the first non-empty level
                            levels.map((level, index) => {
                                if (Array.isArray(selectedUserData.markets[level]) && selectedUserData.markets[level].length > 0) {
                                    return (
                                        <div key={index} style={{ marginBottom: '10px', marginTop: '10px' }}>
                                            <div>
                                                {selectedUserData.markets[level].map((item, idx) => (
                                                    <Typography key={idx}>{item}</Typography>
                                                ))}
                                            </div>
                                        </div>
                                    );
                                }
                                return null; // Skip remaining levels after the first non-empty one is displayed
                            })[0]
                        ) : (
                            // If no specific levels have data, display "markets.markets"
                            Array.isArray(selectedUserData.markets.markets) && selectedUserData.markets.markets.length > 0 && (
                                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                    {selectedUserData.markets.markets.map((item, idx) => (
                                        <Typography key={idx}>{item}</Typography>
                                    ))}
                                </div>
                            )
                        )} */}

                        {Array.isArray(selectedUserData.markets['level 1']) && selectedUserData.markets['level 1'].length > 0 ? (
                            // Display "level 1" data if it exists and has items
                            <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                {selectedUserData.markets['level 1'].map((item, idx) => (
                                    <Typography key={idx}>{item}</Typography>
                                ))}
                            </div>
                        ) : (
                            // If "level 1" is empty or doesn't exist, display "markets.markets" if it has items
                            Array.isArray(marketNames) && marketNames.length > 0 && (
                                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                    {marketNames.map((item, idx) => (
                                        <Typography key={idx}>{item}</Typography>
                                    ))}
                                </div>
                            )
                        )}
                    </div>

                    {/* account info */}
                    <div className={userStyles.marginBottom}>
                        <Typography className={userStyles.userInfoLabel}>Account</Typography>
                        {
                            Array.isArray(accountInfo) && accountInfo.length > 0 ? (
                                accountInfo.map((item, index) => (
                                    <div key={index} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                        <Typography>{item}</Typography>
                                    </div>
                                ))
                            ) : (
                                <Typography>-</Typography>
                            )
                        }
                    </div>

                    <div className={userStyles.marginBottom}>
                        <Typography className={userStyles.userInfoLabel}>Permissions</Typography>
                        {
                            Array.isArray(viewInfo) && viewInfo.length > 0 ? (
                                viewInfo.map((item, index) => (
                                    <div key={index} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                        <Typography>{item}</Typography>
                                    </div>
                                ))
                            ) : (
                                <Typography>-</Typography>
                            )
                        }
                    </div>
                </div>

                <HorizontalDivider />

                {/* <ButtonsRow>
                <Button onClick={() => { setIsVisible(!isVisible); }}>
                    CANCEL
                </Button>
                <Button
                    onClick={() => { setIsVisible(!isVisible); }}
                    variant="call-to-action"
                >
                    OK
                </Button>
            </ButtonsRow> */}
            </OverlayPanel>
        </>

    )
}


export default UserDetailsOverlay;