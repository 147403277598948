import React from 'react';
import App from '@nokia-csf-uxr/ccfk/App';
import GlobalCMAPStyles from '@nokia-csf-uxr/cmap-client-components/styles/GlobalCMAPStyles';
import MapContainer from '@nokia-csf-uxr/cmap-client-components/MapContainer';
import Marker from '@nokia-csf-uxr/cmap-client-components/Marker';
import TileLayer from '@nokia-csf-uxr/cmap-client-components/TileLayer';
import Tooltip from '@nokia-csf-uxr/cmap-client-components/Tooltip';

import * as L from 'leaflet';
import CircleMarker from '@nokia-csf-uxr/cmap-client-components/CircleMarker';





const MapContent = () => {

    
    const center = [40.7128, -74.0060];
    return (
    <App rtl={false}>
      <GlobalCMAPStyles />
    
        <MapContainer
            center={[29.867974, -81.299337]}
            zoom={13}
            attributionControl={true}
            zoomControl={false}
            style={{ height: '620px', marginBottom: '20px' }}
        >
            <TileLayer
                opacity={1.0}
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <CircleMarker
            pathOptions={{ color: 'blue' }}
            center={[20.593684, 78.96288, 5]}
            radius={10}
        />
        <CircleMarker
            pathOptions={{ color: 'blue' }}
            center={[37.09024, -95.712891]}
            radius={10}
        />
        <CircleMarker
            pathOptions={{ color: 'blue' }}
            center={[61.92411, 25.748151]}
            radius={10}
        />
        
            {/* <Marker position={[20.593684, 78.96288]} icon={physical_icon}>
                <Tooltip>Router marker - routerIcon</Tooltip>
            </Marker>
            <Marker position={[37.09024, -95.712891]} icon={physical_icon}>
                <Tooltip>Router marker - routerIcon</Tooltip>
            </Marker>
            <Marker position={[61.92411, 25.748151]} icon={physical_icon}>
                <Tooltip>Router marker - routerIcon</Tooltip>
            </Marker> */}
            
        </MapContainer>

    </App>
)
}

export default MapContent;

