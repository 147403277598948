
import { AppToolbar, SideDrawer, Snackbar } from "@nokia-csf-uxr/ccfk";
import {
  AppBody,
  AppContent,
  AppContentWrapper,
  AppHeader,
} from "@nokia-csf-uxr/ccfk/App";
import {
  AppToolbarContent,
  AppToolbarTitle,
  AppToolbarTitleContainer,
} from "@nokia-csf-uxr/ccfk/AppToolbar";

import { SnackbarContent } from "@nokia-csf-uxr/ccfk/Snackbar";
import { showModal, showPopup } from "Store/auth";
import { RootState } from "Store/mainStore";
import { setTheme } from "Store/theme";
import React, { useState, createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, NavLink } from "react-router-dom";
import DialogModal from "./Elements/DialogModal";
import { deserializeFunction } from "Utils";
import SideDrawerList from "@nokia-csf-uxr/ccfk/SideDrawer/SideDrawerList";
import SideDrawerItem from "@nokia-csf-uxr/ccfk/SideDrawer/SideDrawerItem";

import SubscriptionFilter from "Pages/subscription/SubscriptionFilter";
import { useNavigate } from "react-router-dom";
import BreadCrum from "Pages/marketplace/common/BreadCrum";
import quotationIcon from "../Assets/Icons/quotations_icon.svg";
import FilterButtons from "Pages/marketplace/common/FilterButtons";
import {
  getIconForNavItem,
  getLabelForNavItem,
} from "../Constants/SideNavConstants";
import CommonFilter from "Pages/marketplace/common/CommonFilter";
import { NavBar } from "./NavBar";
import LoadNavLinks from "./LoadNavLinks";
import AccountBody from "Pages/subscription/Account/AccountBody";
import AccountInfo from "Pages/marketplace/Components/AccountInfo";
import Footer from "Pages/Footer";
import CartMessageSnackBar from "Pages/marketplace/common/SnackBar";
import Button, {
  ButtonText,
  ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import AddIcon from '@nokia-csf-uxr/ccfk-assets/icons/latest/add.svg';
import { setProp } from "Store/Actions";
import { SideDrawerButton } from "@nokia-csf-uxr/ccfk/SideDrawer";
import { KEY_CODES } from "@nokia-csf-uxr/ccfk/common";



export const Body = (prop) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themeSelector = useSelector((state: RootState) => state.theme);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const commonSliceSelector = useSelector((state: RootState) => state.common);
  //@ts-ignore
  const SideDrawerButtonRef = createRef(null);
  const [isSideDrawerVisible, setIsSideDrawerVisible] = useState(false);
  // const showButtonBadge = (!!(subMenuOneBadge + subMenuTowBadge) || activeAlarmsBadge) && !isSideDrawerVisible;
  const showButtonBadge = false;
  const marketplaceSelector = useSelector(
    (state: RootState) => state.marketplace
  );
  const userManagementSelector = useSelector(
    (state: RootState) => state.userManagement
  );

  const subscriptionSlice = useSelector(
    (state: RootState) => state.subscription
  );

  const buttonConfigForAdmin = {
    "User Management": {
      label: "Add user/API user",
      onClick: () => dispatch(setProp({ slice: "userManagement", key: "showUserModal", value: true })),
    },
    "Roles": {
      label: "Add role",
      onClick: () => dispatch(setProp({ slice: "userManagement", key: "showAddRoleDialog", value: true })),
    },
    // Add more configurations as needed for other pages
  };

  const SideDrawerProps = {
    onKeyDown: (event) => {
      event.key === KEY_CODES.ESCAPE && setIsSideDrawerVisible(false);;
    },
    visible: false,
    className: "dot-side-drawer",
    //variant: "persistent", // Default is "temporary"
    //variant: "temporary",
    variant: "compact",
    //elevationProps: {elevationIndex: 12},
  };

  const SideDrawerButtonProps = {
    badged: showButtonBadge,
    buttonProps: {
      ref: SideDrawerButtonRef,
      onClick: () => {
        setIsSideDrawerVisible(!isSideDrawerVisible);
      },
      "aria-label":
        showButtonBadge && !isSideDrawerVisible
          ? "Side Navigation Menu new items"
          : "Side Navigation Menu",
    },
    onKeyUp: (event) => {
      //event.key === KEY_CODES.ARROW_DOWN &&  sideDrawerRef.current.focus();
    },
  };

  const getCurrentPageForAdmin = (items) => {
    return items.length > 0 ? items[0].title : "Unknown"; // Use the first item's title as the current page
  };

  // Check if the current page is an Admin page
  const isAdminPage = prop.crumbs[0] === "Admin";

  // Get the current admin subpage only if it's an Admin page
  const currentAdminSubpage = isAdminPage ? getCurrentPageForAdmin(prop.items) : null;

  // Retrieve the button configuration based on the current subpage
  const buttonConfig = isAdminPage && currentAdminSubpage ? buttonConfigForAdmin[currentAdminSubpage] : null;



  return (
    <>
      <AppBody>
        <SideDrawer {...(commonSliceSelector.sideBarOpen ? SideDrawerButtonProps : SideDrawerProps)}>
          <div style={{ height: "100%", boxShadow: "none" }}>
            <SideDrawerList>
              {marketplaceSelector.userAccessInfo &&
                marketplaceSelector.userAccessInfo.map(LoadNavLinks)}
            </SideDrawerList>
          </div>
        </SideDrawer>
        <AppContentWrapper style={{ marginLeft: commonSliceSelector.sideBarOpen ? '15px' : '70px', marginRight: '10px' }}>
          {prop.crumbs[0] === "Marketplace" && (
            <>
              <AppHeader>
                <AppToolbar elevationProps={{ elevationIndex: 0 }} style={{ borderRadius: '10px' }}>
                  
                  <AppToolbarTitleContainer>
                    <AppToolbarTitle style={{ padding: "0px !important" }}>
                      <BreadCrum items={prop.items} />
                    </AppToolbarTitle>
                  </AppToolbarTitleContainer>
                  <AppToolbarContent>
                    {/* <MarketplaceTitleBar /> */}
                    {/* <CommonFilter /> */}
                    <AccountInfo />
                    <FilterButtons />
                  </AppToolbarContent>
                </AppToolbar>
              </AppHeader>
            </>
          )}
          {prop.crumbs[0].startsWith("Subscription") && (
            <>
              <AppHeader>
                <AppToolbar elevationProps={{ elevationIndex: 0 }} style={{ borderRadius: '10px' }}>
                  <AppToolbarTitleContainer>
                    <AppToolbarTitle>
                      {subscriptionSlice?.AppToolbarTitle}
                    </AppToolbarTitle>
                  </AppToolbarTitleContainer>
                  <AppToolbarContent>{""}</AppToolbarContent>
                </AppToolbar>
              </AppHeader>
            </>
          )}

          {prop.crumbs[0] === "Marketplace / Cart" && (
            <>
              <AppHeader>
                <AppToolbar elevationProps={{ elevationIndex: 0 }} style={{ borderRadius: '10px' }}>
                  <AppToolbarTitleContainer>
                    <AppToolbarTitle>
                      <BreadCrum items={prop.items} separator=" > " />
                    </AppToolbarTitle>
                  </AppToolbarTitleContainer>
                  <AppToolbarContent>{""}</AppToolbarContent>
                </AppToolbar>
              </AppHeader>
            </>
          )}

          {prop.crumbs[0] === "Marketplace / Product" && (
            <>
              <AppHeader>
                <AppToolbar elevationProps={{ elevationIndex: 0 }} style={{ borderRadius: '10px' }}>
                  <AppToolbarTitleContainer>
                    <AppToolbarTitle>
                      <BreadCrum items={prop.items} separator=" > " />
                    </AppToolbarTitle>
                  </AppToolbarTitleContainer>
                  <AppToolbarContent>
                    {/* <MarketplaceTitleBar /> */}
                    <AccountInfo />
                    <FilterButtons />
                  </AppToolbarContent>
                </AppToolbar>
              </AppHeader>
            </>
          )}

          {prop.crumbs[0] === "Quotations" && (
            <>
              <AppHeader>
                <AppToolbar elevationProps={{ elevationIndex: 0 }} style={{ borderRadius: '10px' }}>
                  <AppToolbarTitleContainer>
                    <AppToolbarTitle>
                      <BreadCrum items={prop.items} separator=" > " />
                    </AppToolbarTitle>
                  </AppToolbarTitleContainer>
                  <AppToolbarContent>
                    {/* <QuotationsFilter /> */}
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                      <CommonFilter />
                    </div>
                    {/* <FilterButtons /> */}
                    {""}
                  </AppToolbarContent>
                </AppToolbar>
              </AppHeader>
            </>
          )}

          {isAdminPage && buttonConfig && (
            <AppHeader>
              <AppToolbar elevationProps={{ elevationIndex: 0 }} style={{ borderRadius: '10px' }}>
                <AppToolbarTitleContainer>
                  <AppToolbarTitle>
                    <BreadCrum items={prop.items} separator=" > " />
                  </AppToolbarTitle>
                </AppToolbarTitleContainer>
                <AppToolbarContent>
                  <Button
                    variant="call-to-action"
                    onClick={buttonConfig.onClick}
                  >
                    <ButtonText>{buttonConfig.label}</ButtonText>
                  </Button>
                </AppToolbarContent>
              </AppToolbar>
            </AppHeader>
          )}

          {prop.crumbs[0] === "Orders" && (
            <>
              <AppHeader>
                <AppToolbar elevationProps={{ elevationIndex: 0 }} style={{ borderRadius: '10px' }}>
                  <AppToolbarTitleContainer>
                    <AppToolbarTitle>
                      <BreadCrum items={prop.items} separator=" > " />
                    </AppToolbarTitle>
                  </AppToolbarTitleContainer>
                  <AppToolbarContent>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                      <CommonFilter />
                    </div>
                  </AppToolbarContent>
                </AppToolbar>
              </AppHeader>
            </>
          )}

          {prop.crumbs[0] === "DashBoard" && (
            <>
              <AppHeader>
                <AppToolbar elevationProps={{ elevationIndex: 0 }} style={{ borderRadius: '10px' }}>
                  <AppToolbarTitleContainer>
                    <AppToolbarTitle>
                      <BreadCrum items={prop.items} separator=" > " />
                    </AppToolbarTitle>
                  </AppToolbarTitleContainer>
                  <AppToolbarContent>
                    {""}
                  </AppToolbarContent>
                </AppToolbar>
              </AppHeader>
            </>
          )}

          {prop.crumbs[0] === "Merchant Products" && (
                      <>
                        <AppHeader>
                          <AppToolbar elevationProps={{ elevationIndex: 0 }} style={{ borderRadius: '10px' }}>
                            <AppToolbarTitleContainer>
                              <AppToolbarTitle>
                                <BreadCrum items={prop.items} separator=" > " />
                              </AppToolbarTitle>
                            </AppToolbarTitleContainer>
                            <AppToolbarContent>
                              {""}
                            </AppToolbarContent>
                          </AppToolbar>
                        </AppHeader>
                      </>
                    )}

          {prop.crumbs[0] === "Merchant Orders" && (
                      <>
                        <AppHeader>
                          <AppToolbar elevationProps={{ elevationIndex: 0 }} style={{ borderRadius: '10px' }}>
                            <AppToolbarTitleContainer>
                              <AppToolbarTitle>
                                <BreadCrum items={prop.items} separator=" > " />
                              </AppToolbarTitle>
                            </AppToolbarTitleContainer>
                            <AppToolbarContent>
                              {""}
                            </AppToolbarContent>
                          </AppToolbar>
                        </AppHeader>
                      </>
                    )}

          <AppContent
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: "10px",
              width: "100%",
              // marginRight: '10px'
            }}
          >
            {prop.bodyContent}

            <Snackbar
              onClick={() =>
                dispatch(
                  showPopup({ msg: "", show: !authSelector.system.popup.show })
                )
              }
              autoCloseDuration={2000}
              open={authSelector.system.popup.show}
              autoClose={true}
            >
              <SnackbarContent>{authSelector.system.popup.msg}</SnackbarContent>
            </Snackbar>
            <DialogModal
              type="popup"
              isOpen={authSelector.system.modal.show}
              popInfo={{
                msg: authSelector.system.modal.msg,
                title: authSelector.system.modal.title,
                variant: authSelector.system.modal.variant,
                panel: {
                  msg: authSelector.system.modal.panel.msg,
                  title: authSelector.system.modal.panel.title,
                  show: themeSelector.data.panel.isOpen.global,
                  onExpand: () => {
                    dispatch(
                      setTheme({
                        element: "panel",
                        comp: "global",
                        prop: "isOpen",
                        value: !themeSelector.data.panel.isOpen.global,
                      })
                    );
                  },
                },
              }}
              //@ts-ignore
              actionBtns={
                authSelector.system.modal.action.length === 0
                  ? [
                    {
                      label: "Close",
                      variant: "call-to-action",
                      onAction: () => {
                        dispatch(
                          showModal({
                            msg: "",
                            panel: { title: "", msg: "" },
                            show: !authSelector.system.modal.show,
                          })
                        );
                      },
                    },
                  ]
                  : authSelector.system.modal.action.map((payload) => ({
                    ...payload,
                    onAction: deserializeFunction(payload.onAction),
                  }))
              }
            />



          </AppContent>
          {/* {!location.pathname.includes("subscription") && !location.pathname.includes("account") && <Footer />} */}
          <Footer />
        </AppContentWrapper>

      </AppBody>

    </>
  );
};
