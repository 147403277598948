import React, { useState, useEffect } from "react";
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import Label from '@nokia-csf-uxr/ccfk/Label';
import { setProp } from "Store/Actions";
import userStyles from "../Style/UserManagement.module.css";
import HierachyTree from "../components/HierachyTree";
import AccountListSmartSelect from "../components/AccountListSmartSelect";
import { Button, TextInput } from "@nokia-csf-uxr/ccfk";
import { setUserInputFields, updateSelectedPersonas, updateRoles, setOotRoles } from "Store/contentState/userManagementSlice";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import CommonFormFields from "./CommonFormFields";
import OOT from "Constants/services/oot";
import { modifyData, getRequestedInfo } from "../utils/constants";
import CommonNameEmailField from "./CommonEmailField";
import SelectBoxWithCustomSearch from "Pages/custom-components/SelectBoxWithCustomSearch";
import { getAccountDropdownLabel } from "../utils/constants";
import CommonFromOotApprover from "./CommonFromOotApprover";
import CommonOrganizationType from "./CommonOrganizatinoType";


const UserFormDetails = (prop) => {
  const dispatch = useDispatch();
  const userFullName = useSelector((state: RootState) => state.userManagement.userData.fullName);
  const userEmailAddress = useSelector((state: RootState) => state.userManagement.userData.emailAddress);
  const userOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
  const organizationTypes = useSelector((state: RootState) => state.userManagement.metaData.persona);
  const modifiedOrganizationTypes = modifyData(organizationTypes, 'id', 'name');
  const filteredExternalTypes = modifiedOrganizationTypes.filter((type) => type.id !== "nokia");
  const allAccountsForExternal = useSelector((state: RootState) => state.userManagement.allAccountsForExternal);
  const supplierAccount = useSelector((state: RootState) => state.userManagement.userData.supplier_account_id);
  const accountThreeStar = useSelector((state: RootState) => state.userManagement.userData.threeStarNumber);
  const isAddingNokiaUser = useSelector((state: RootState) => state.userManagement.isAddingNokiaUser);
  const isEmailEntered = useSelector((state: RootState) => state.userManagement.isEmailEntered);
  const loadDataList = useSelector((state: RootState) => state.userManagement.loadDataList);
  const allAccountList = useSelector((state: RootState) => state.userManagement.allAccountList)
  const [allAccounts, setAllAccounts] = useState([]);
  const isEditMode = prop.mode === 'edit';
  const selectItemsList = useSelector((state: RootState) => state.userManagement.selectedItems);
  const label = getAccountDropdownLabel(userOrgType[0], modifiedOrganizationTypes);
  const [editUserOneStartNum, setEditUserOneStartNum] = useState(false);



  const handleUserFullName = (name) => {
    dispatch(setUserInputFields({ field: 'fullName', value: name }));
  };




  useEffect(() => {
    if (userOrgType && userOrgType.length > 0 && userOrgType[0] !== "nokia") {
      //@ts-ignore
      fetchAccountBasedOnPersona("", 0);
    }

  }, [userOrgType]);

  const getSearchListData = (filterValue) => {
    console.log(filterValue, 'filterValue');
    if (loadDataList) {
      fetchAccountBasedOnPersona(filterValue, 10)
    }
  }


  const fetchAccountBasedOnPersona = async (filterValue, offsetValue) => {
    try {

      let requested_info = getRequestedInfo(userOrgType[0]);
      //@ts-ignore
      let filter = generateFilter(requested_info, filterValue);
      const limit = 10;
      const offset = calculateOffset(filterValue, allAccountsForExternal, offsetValue);

      console.log(requested_info, 'requested_info');

      let response = {};
      let responsenew = {};

      //@ts-ignore
      if (requested_info === "supplier" || requested_info === "merchant") {
        response = await OOT.getAccountDetailsMerchant(filter, limit, offset, [], requested_info);
      } else {
        response = await OOT.getAccountsForInternalExternal(filter, limit, offset, requested_info);
      }

      if (response['status']) {
        //console.log(response['data'], 'response.data');
        //@ts-ignore
        if (requested_info !== "supplier" || requested_info !== "merchant") {
          setAllAccounts(response['data']);
        }
        let modifiedAccounts = modifyData(response['data'], requested_info !== "supplier" ? requested_info !== "merchant" ? 'parent_account_number' : 'account_id' : 'acount_id', 'name');
        dispatch(setProp({ slice: 'userManagement', key: 'loadDataList', value: response['data'].length === 0 ? false : true }))
        let datalist = [...allAccountsForExternal, ...modifiedAccounts]
        let allAccountListData = [...allAccountList, ...response['data']]
        if (prop.mode == 'edit') {
          let userOneStartNum = datalist.filter((item) => item.id === prop.selectedData.oneStarNumber[0])
          if (userOneStartNum && userOneStartNum.length === 0 && selectItemsList.length === 0) {
            setEditUserOneStartNum(true)
            if (requested_info === "supplier" || requested_info === "merchant") {
              responsenew = await OOT.getAccountDetailsMerchant(filter, limit, offset, prop.selectedData.supplier_account_id, requested_info,);
            }
            else {
              responsenew = await OOT.getAccountsForInternalExternal(filter, limit, offset, requested_info, prop.selectedData.oneStarNumber);
            }
            let modifiedAccountsNew = modifyData(responsenew['data'], requested_info !== "supplier" ? requested_info !== "merchant" ? 'parent_account_number' : 'account_id' : 'account_id', 'name', true);
            let datalistNew = [...datalist, ...modifiedAccountsNew]
            dispatch(setProp({ slice: 'userManagement', key: 'selectedItems', value: modifiedAccountsNew }))
            dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: datalistNew }))
            let allAccountListDataNew = [...allAccountListData, ...responsenew['data']]
            dispatch(setProp({ slice: 'userManagement', key: 'allAccountList', value: allAccountListDataNew }))
          }
        }
        dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: datalist }))
        dispatch(setProp({ slice: 'userManagement', key: 'allAccountList', value: allAccountListData }))
      } else {
        dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: [] }))
      }
    } catch (error) {
      console.log(error);
    }
  };


  // Helper to generate the filter condition
  const generateFilter = (requested_info, filterValue) => {
    if (requested_info === "merchant") {
      let filter = "is_merchant = 1";
      if (filterValue) {
        filter += ` AND name LIKE '%${filterValue}%'`;
      }
      return filter;
    }
    return filterValue ? `name LIKE '%${filterValue}%'` : "";
  };

  // Helper to calculate the offset
  const calculateOffset = (filterValue, allAccountsForExternal, offsetValue) => {
    return filterValue !== '' ? 0 : allAccountsForExternal.length + offsetValue;
  };


  // // Helper to fetch data based on requested_info
  // const fetchDataByPersona = async (requested_info, filter, limit, offset) => {
  //   if (requested_info === "supplier" || requested_info === "merchant") {
  //     return await OOT.getAccountDetailsMerchant(filter, limit, offset, [], requested_info);
  //   }
  //   return await OOT.getAccountsForInternalExternal(filter, limit, offset, requested_info);
  // };

  // // Helper to process fetched data
  // const processResponseData = (response, requested_info, allAccountsForExternal, allAccountList) => {
  //   if (response?.status) {
  //     const modifiedAccounts = modifyData(
  //       response['data'],
  //       requested_info !== "supplier" ? 'parent_account_number' : 'account_id',
  //       'name'
  //     );

  //     const datalist = [...allAccountsForExternal, ...modifiedAccounts];
  //     const allAccountListData = [...allAccountList, ...response['data']];

  //     return { datalist, allAccountListData, modifiedAccounts };
  //   }
  //   return { datalist: [], allAccountListData: [], modifiedAccounts: [] };
  // };


  // const handleAccountsResponse = async (data, requestedInfo, filter, limit, offset) => {
  //   const isSupplierOrMerchant = requestedInfo === "supplier" || requestedInfo === "merchant";
  //   const idField = getIdField(requestedInfo);

  //   if (!isSupplierOrMerchant) {
  //     setAllAccounts(data);
  //   }

  //   const modifiedAccounts = modifyData(data, idField, 'name');
  //   updateStateWithAccounts(modifiedAccounts, data);

  //   if (prop.mode === 'edit') {
  //     await handleEditMode(modifiedAccounts, requestedInfo, filter, limit, offset);
  //   }
  // };


  // const getIdField = (requestedInfo) => {
  //   return requestedInfo === "supplier" || requestedInfo === "merchant"
  //     ? 'account_id'
  //     : 'parent_account_number';
  // };

  // const updateStateWithAccounts = (modifiedAccounts, originalData) => {
  //   const datalist = [...allAccountsForExternal, ...modifiedAccounts];
  //   const allAccountListData = [...allAccountList, ...originalData];

  //   dispatch(setProp({ slice: 'userManagement', key: 'loadDataList', value: originalData.length > 0 }));
  //   dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: datalist }));
  //   dispatch(setProp({ slice: 'userManagement', key: 'allAccountList', value: allAccountListData }));
  // };

  // const handleEditMode = async (modifiedAccounts, requestedInfo, filter, limit, offset) => {
  //   const userOneStartNum = modifiedAccounts.filter(item => item.id === prop.selectedData.oneStarNumber[0]);

  //   if (userOneStartNum.length === 0 && selectItemsList.length === 0) {
  //     setEditUserOneStartNum(true);

  //     const responsenew = await fetchAccountsForEditMode(requestedInfo, filter, limit, offset);
  //     const modifiedAccountsNew = modifyData(responsenew.data, getIdField(requestedInfo), 'name', true);

  //     const datalistNew = [...modifiedAccounts, ...modifiedAccountsNew];
  //     const allAccountListDataNew = [...allAccountList, ...responsenew.data];

  //     dispatch(setProp({ slice: 'userManagement', key: 'selectedItems', value: modifiedAccountsNew }));
  //     dispatch(setProp({ slice: 'userManagement', key: 'allAccountsForExternal', value: datalistNew }));
  //     dispatch(setProp({ slice: 'userManagement', key: 'allAccountList', value: allAccountListDataNew }));
  //   }
  // };

  // const fetchAccountsForEditMode = async (requestedInfo, filter, limit, offset) => {
  //   if (requestedInfo === "supplier" || requestedInfo === "merchant") {
  //     return await OOT.getAccountDetailsMerchant(filter, limit, offset, prop.selectedData.supplier_account_id, requestedInfo);
  //   }
  //   return await OOT.getAccountsForInternalExternal(filter, limit, offset, requestedInfo, prop.selectedData.oneStarNumber);
  // };


  const handleAccountSelection = (account) => {
    if (account) {
      let filteredAccount = allAccountsForExternal.filter((item) => item.id === account);
      if (userOrgType[0] === "merchant") {
        dispatch(setUserInputFields({ field: 'supplier_account_id', value: [filteredAccount[0].id] }));
        dispatch(setUserInputFields({ field: 'threeStarNumber', value: [] }));
        dispatch(setUserInputFields({ field: 'oneStarNumber', value: [] }));
        dispatch(setUserInputFields({ field: 'business_name', value: [] }));
      } else {
        let accountDetails = allAccountList.filter((item) => item.parent_account_number === account);
        dispatch(setUserInputFields({ field: 'threeStarNumber', value: [accountDetails[0].parent_account_number] }));
        dispatch(setUserInputFields({ field: 'oneStarNumber', value: [accountDetails[0].customer_account_number] }));
        dispatch(setUserInputFields({ field: 'business_name', value: [accountDetails[0].business_name] }));
        dispatch(setOotRoles({ type: 'oneStarNum', value: [accountDetails[0].customer_account_number] }));
      }
      dispatch(setProp({ slice: 'userManagement', key: 'selectedItems', value: [] }))
    }
    else {
      dispatch(setProp({ slice: 'userManagement', key: 'selectedItems', value: [] }))
    }

  }



  return (
    <>
      <div className={userStyles.gridContainerNew}>
        <div className={`${userStyles.marginBottom}`}>
          <Label className={userStyles.userLabel}>Full name <span style={{ color: '#C91514' }}>*</span></Label>

          <TextInput
            id="full-name"
            disabled={prop.mode === "edit" && prop.selectedData.name.trim() !== ""}
            value={userFullName}
            variant={"outlined"}
            placeholder="Enter full name"
            onChange={(e) => handleUserFullName(e.target.value)}
          />

        </div>

        <CommonNameEmailField mode={prop.mode} />
        <CommonOrganizationType mode={prop.mode} />
        {/* {
          ((isEmailEntered && isAddingNokiaUser) || prop.mode === "edit") ? (
            <div className={`${userStyles.marginBottom}`}>
              <Label className={userStyles.userLabel}>Organization type <span style={{ color: '#C91514' }}>*</span></Label>
              <TextInput
                id="organization-type"
                disabled={true}
                value={userOrgType[0]}
                variant={"outlined"}
                placeholder=""
              />
            </div>
          ) : (
            isEmailEntered && (<div className={`${userStyles.marginBottom}`}>
              <Label className={userStyles.userLabel}>Organization type <span style={{ color: '#C91514' }}>*</span></Label>
              <SelectBox
                placeholder="Select"
                maxWidth={true}
                caseInsentiveMatching={true}
                selectedItem={userOrgType[0]}
                data={filteredExternalTypes}
                onUpdateSelection={handleOrgTypeSelection}
                isLoading={false}
              />
            </div>)
          )
        } */}



        {(isAddingNokiaUser || (userOrgType[0] && !isAddingNokiaUser)) && <CommonFormFields mode={prop.mode} />}

        {((isEmailEntered && !isAddingNokiaUser && userOrgType[0] && allAccountsForExternal) || (prop.mode === 'edit' && userOrgType[0] !== 'nokia')) && <div className={userStyles.gridItem}>
          <div className={userStyles.selectBoxStyle}>
            <div style={{ width: '100%' }}>
              <Label className={userStyles.userLabel}>{label} <span style={{ color: '#C91514' }}>*</span></Label>
              <SelectBoxWithCustomSearch
                placeholder="Select account"
                maxWidth={true}
                caseInsentiveMatching={true}
                selectedItem={userOrgType[0] === "merchant" ? supplierAccount[0] : accountThreeStar[0]}
                data={allAccountsForExternal}
                onUpdateSelection={handleAccountSelection}
                isLoading={false}
                operationType={prop.mode}
                fetchRecords={getSearchListData}
                loadDataList={loadDataList}
                selectedItemsList={selectItemsList[0]}
              />
            </div>
          </div>
        </div>
        }
        <CommonFromOotApprover mode={prop.mode} />
      </div>

      <div className={userStyles.gridContainer}>
        {((isEmailEntered && isAddingNokiaUser) || (prop.mode === 'edit' && userOrgType[0] === 'nokia')) && <div style={{ display: 'flex' }}>
          <HierachyTree mode={prop.mode} />
          <AccountListSmartSelect mode={prop.mode} />




        </div>}
      </div>
    </>
  )
}

export default UserFormDetails;