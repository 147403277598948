
import React, { useState, useRef, useEffect } from "react";
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store/mainStore";
import Checkbox, {
    CheckboxLabelContent,
} from '@nokia-csf-uxr/ccfk/Checkbox';
import Card from '@nokia-csf-uxr/ccfk/Card';
import { Typography } from "@nokia-csf-uxr/ccfk";
import TOKENS from '@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6';
import ExpansionPanelsContainer, {
    ExpansionPanel,
    ExpansionPanelTitle,
    ExpansionPanelHeader,
    ExpansionPanelBody,
    ExpansionPanelButton
} from '@nokia-csf-uxr/ccfk/ExpansionPanels';
import Button from '@nokia-csf-uxr/ccfk/Button';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import userStyles from './Style/UserManagement.module.css';
import OOT from "Constants/services/oot";
import businessHubIcon from "../../../Assets/Icons/roles-business-hub-icon.svg"
import dataFieldsIcon from "../../../Assets/Icons/data-fields-icon.svg"
import { getContext, getEasing, getDuration, isAnimationDisabled } from '@nokia-csf-uxr/ccfk/common/index.js';
import RolesActions from "./RolesActions";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import MultiSelect from "Pages/marketplace/common/MultiSelect";
import { setProp } from "Store/Actions";
import {modifyData} from "../user-management/utils/constants";
import SnackBar from "Pages/marketplace/common/SnackBar";
import AddRoleDialog from "./AddRoleDialog";
import { setEditMode } from "Store/contentState/userManagementSlice";
import Loader from "Components/Elements/Loader";
import useFetchRoles from "../user-management/hooks/useFetchRoles";
import { setRolesInfo } from "Store/contentState/userManagementSlice";

const DATA = [

    {
        id: '2',
        name: 'Data fields',
        // number: '063-680-0079'
    },
];

const PANELData = [
    {
        id: '1',
        name: 'DAC Business Hub',
        // number: '063-680-0079'
    },

]




const UserRoles = () => {
    const dispatch = useDispatch();
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const applicationList = useSelector((state: RootState) => state.userManagement.metaData.applicationList[0]['applications']);
    const dataElements = useSelector((state: RootState) => state.userManagement.metaData.dataElements[0]['dataElement']);
    const personaList = useSelector((state: RootState) => state.userManagement.metaData.persona);
    const userRolesColumns = useSelector((state: RootState) => state.userManagement.userRolesFilterColumns);
    const groupDisplayType = 'groupRows';
    const gridApi = useRef(null);
    const [expanded, setExpanded] = React.useState(undefined);
    // const [rolesData, setRolesData] = useState([]);
    const [modifiedRoles, setModifiedRoles] = useState([]);
    const headerRef = React.useRef(null);
    const isEditMode = useSelector((state: RootState) => state.userManagement.isEditMode);
    const showRoleDialog = useSelector((state: RootState) => state.userManagement.showAddRoleDialog);
    const {rolesData, loading} = useSelector((state: RootState) => state.userManagement.roleInfo);

    const {fetchUserRoles} = useFetchRoles();



    let filteredDataElements =  Object.keys(dataElements).map(key => ({
        id: key,   
        name: key.charAt(0).toUpperCase() + key.slice(1),
        actions: {
           values: dataElements[key] 
        }
    }));

    //console.log(filteredDataElements)

    const filteredApplicationList = applicationList.map((item) => {
        return {
            id: item,
            name: item.charAt(0).toUpperCase() + item.slice(1)
        };
    });
   
    
    const organizationTypeList = [
        {
            id: "1",
            name: "Organization type "
        }
    ]

    const modifiedPersonaData = modifyData(personaList, 'id', 'name');


    useEffect(() => {
        dispatch(setEditMode(false))
        fetchUserRoles("");
    }, [])


    // const filteredDataElements = filterParentElements(dataElements);

    // function filterParentElements(data) {
    //     const parentAPIs = data.map(item => item.api).filter(api => !api.includes('/', 1)); // Get parent APIs (without additional slashes after the first one)

    //     return data.filter(item => parentAPIs.includes(item.api) || !parentAPIs.some(parentApi => item.api.startsWith(parentApi + '/')));
    // }



    useEffect(() => {
        if (modifiedRoles.length > 0) {
            dispatch(setProp({
                slice: 'userManagement', key: 'modifiedRoles', value: modifiedRoles
            }));
        }
    }, [modifiedRoles]);

    const handleExpansion = id => (e) => {
        const newId = expanded === id ? undefined : id;
        if (e.type === 'keydown') {
            if (
                e.target.getAttribute('data-test') === 'header' && // check if keydown from header
                (e.key === ' ' || e.key === 'Enter')
            ) {
                setExpanded(newId);
            }
        }
        if (e.type === 'click') {
            setExpanded(newId);
        }
    };


    const handleCloseSnackBar = () => {
        setSnackbarMessage("");
    };

    const filteredRolesData = userRolesColumns.length === 0
        ? rolesData // Show all if array is empty
        : rolesData.filter(role =>
            userRolesColumns.includes(role.id)
        );

    // Generate columns for DataGrid dynamically based on roles
    const columns = [
        {
            field: "name", headerName: "Pages", minWidth: 150, editable: false, pinned: "left", enableRowGroup: true
        },
        ...filteredRolesData.map((role) => ({
            field: role.id,
            headerName: role.name,
            minWidth: 250,
            cellRenderer: (params) => {
                // Check if data_objects or view is undefined
                const isChecked = role?.data_objects?.view?.includes(params.data.id) || false;
                return (
                    <Checkbox
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(role.id, params.data.id)}
                        disabled={!isEditMode}
                    />
                );
            },
        })),
    ];

    // Columns for Data Elements Grid
    const dataElementsColumns = [
        { field: "name", headerName: "Data Elements", minWidth: 150, editable: false, pinned: "left" },
        ...filteredRolesData.map((role) => ({
            field: role.id,
            headerName: role.name,
            minWidth: 250,
            cellRenderer: (params) => {

                // Determine which actions are allowed for the data element
                const { data } = params;
                const hasCreate = data?.actions?.values?.includes("create") || false;
                const hasRead = data?.actions?.values?.includes("view") || false;
                const hasUpdate = data?.actions?.values?.includes("update") || false;
                const hasDelete = data?.actions?.values?.includes("delete") || false;

                // Check if the role already has these permissions for the data element
                const isCreateChecked = role?.data_objects?.data_elements?.[data.id]?.includes("create") || false;
                const isReadChecked = role?.data_objects?.data_elements?.[data.id]?.includes("view") || false;
                const isUpdateChecked = role?.data_objects?.data_elements?.[data.id]?.includes("update") || false;
                const isDeleteChecked = role?.data_objects?.data_elements?.[data.id]?.includes("delete") || false;

                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {hasCreate && (
                            <div style={{ display: 'flex', marginRight: 4 }}>
                                <Checkbox
                                    checked={isCreateChecked}
                                    onChange={() =>
                                        handleDataElementsCheckboxChange(role.id, data.id, "create")
                                    }
                                    disabled={!isEditMode}
                                />
                                <CheckboxLabelContent>C</CheckboxLabelContent>
                            </div>
                        )}
                        {hasRead && (
                            <div style={{ display: 'flex', marginRight: 4 }}>
                                <Checkbox
                                    checked={isReadChecked}
                                    onChange={() =>
                                        handleDataElementsCheckboxChange(role.id, data.id, "view")
                                    }
                                    disabled={!isEditMode}
                                />
                                <CheckboxLabelContent>R</CheckboxLabelContent>
                            </div>
                        )}
                        {hasUpdate && (
                            <div style={{ display: 'flex', marginRight: 4 }}>
                                <Checkbox
                                    checked={isUpdateChecked}
                                    onChange={() =>
                                        handleDataElementsCheckboxChange(role.id, data.id, "update")
                                    }
                                    disabled={!isEditMode}
                                />
                                <CheckboxLabelContent>U</CheckboxLabelContent>
                            </div>
                        )}
                        {hasDelete && (
                            <div style={{ display: 'flex' }}>
                                <Checkbox
                                    checked={isDeleteChecked}
                                    onChange={() =>
                                        handleDataElementsCheckboxChange(role.id, data.id, "delete")
                                    }
                                    disabled={!isEditMode}
                                />
                                <CheckboxLabelContent>D</CheckboxLabelContent>
                            </div>
                        )}
                    </div>
                );
            },
        })),
    ];



    const handleOrganizationTypeSelection = (selected, roleId) => {
        const selectedIds = selected.map(item => item.id);

        const updatedRolesData = rolesData.map(role => {
            if (role.id === roleId) {
                const modifiedRole = {
                    ...role,
                    data_objects: {
                        ...role.data_objects,
                        allowed_org_type: selectedIds,
                    }
                };

                // Track modified roles
                setModifiedRoles(prev => {
                    const existingRoleIndex = prev.findIndex(modifiedRole => modifiedRole.id === roleId);
                    if (existingRoleIndex !== -1) {
                        return prev.map((role, index) =>
                            index === existingRoleIndex ? modifiedRole : role
                        );
                    } else {
                        return [...prev, modifiedRole];
                    }
                });

                return modifiedRole;
            }
            return role;
        });

        //setRolesData(updatedRolesData);
        dispatch(setRolesInfo({ field: 'rolesData', value: updatedRolesData }));
    };


    // Organization data columns

    const getSelectedItemsForRole = (roleId) => {
        const role = rolesData.find(role => role.id === roleId);
        return modifiedPersonaData.filter(persona => role?.data_objects?.allowed_org_type?.includes(persona.id));
    };

    const organizationData = [
        {
            field: "name", headerName: "Permissions", minWidth: 150, editable: false, pinned: "left", cellStyle: {
                backgroundColor: '#F6EFFF',

            }
        },
        ...filteredRolesData.map((role) => ({
            field: role.id,
            headerName: role.name,
            minWidth: 250,
            cellStyle: {
                backgroundColor: '#F6EFFF',

            },
            cellRenderer: (params) => {
                //multiselect dropdown for organization type

                const selectedOrgTypes = getSelectedItemsForRole(role.id);
                return (
                    <MultiSelect
                        placeholder={"Select org type"}
                        label={""}
                        data={modifiedPersonaData}
                        selectedItems={selectedOrgTypes}
                        disabled={!isEditMode}
                        onSelected={(selectedItems) => handleOrganizationTypeSelection(selectedItems, role.id)}
                    //onSelected={handleOrganizationTypeSelection}
                    />
                )
            },
        })),
    ]





    // Checkbox change handler for Pages
    // const handleCheckboxChange = (roleId, pageId) => {
    //     const updatedRoles = rolesData.map((role) => {
    //         if (role.id === roleId) {
    //             const updatedViews = role?.data_objects?.view?.includes(pageId)
    //                 ? role.data_objects.view.filter((viewId) => viewId !== pageId)
    //                 : [...(role.data_objects?.view || []), pageId];

    //             return {
    //                 ...role,
    //                 data_objects: {
    //                     ...role.data_objects,
    //                     view: updatedViews,
    //                 },
    //             };
    //         }
    //         return role;
    //     });

    //     setRolesData(updatedRoles);
    // };


    // const handleCheckboxChange = (roleId, pageId) => {
    //     const updatedRoles = rolesData.map((role) => {
    //         if (role.id === roleId) {
    //             const updatedViews = role?.data_objects?.view?.includes(pageId)
    //                 ? role.data_objects.view.filter((viewId) => viewId !== pageId)
    //                 : [...(role.data_objects?.view || []), pageId];

    //             // Track modified roles
    //             setModifiedRoles(prev => ({
    //                 ...prev, // Keep existing modified roles
    //                 // Create or update the role with the given roleId
    //                 ...rolesData.find(role => role.id === roleId), // Find the original role data by id
    //                 data_objects: {
    //                     ...rolesData.find(role => role.id === roleId).data_objects, // Spread existing data_objects
    //                     view: updatedViews, // Update the view property with new values
    //                 },

    //             }));

    //             console.log(modifiedRoles, 'modified roles')
    //             return {
    //                 ...role,
    //                 data_objects: {
    //                     ...role.data_objects,
    //                     view: updatedViews,
    //                 },
    //             };
    //         }

    //         return role;
    //     });
    //     // console.log(updatedRoles, 'updated roles')
    //     setRolesData(updatedRoles);
    // };


    const handleCheckboxChange = (roleId, pageId) => {
        const updatedRoles = rolesData.map((role) => {
            if (role.id === roleId) {
                // Determine if we are adding or removing the pageId from the views
                const updatedViews = role?.data_objects?.view?.includes(pageId)
                    ? role.data_objects.view.filter((viewId) => viewId !== pageId) // Remove if exists
                    : [...(role.data_objects?.view || []), pageId]; // Add if not exists

                // Create a modified role object
                const modifiedRole = {
                    ...role,
                    data_objects: {
                        ...role.data_objects,
                        view: updatedViews,
                    },
                };

                // Track modified roles
                setModifiedRoles(prev => {
                    // Check if the role already exists in modifiedRoles
                    const existingRoleIndex = prev.findIndex(modifiedRole => modifiedRole.id === roleId);

                    // If it exists, update it; otherwise, add it to the array
                    if (existingRoleIndex !== -1) {
                        return prev.map((role, index) =>
                            index === existingRoleIndex ? modifiedRole : role
                        );
                    } else {
                        // Add the new modified role
                        return [...prev, modifiedRole];
                    }
                });

                console.log(modifiedRoles, 'modified roles'); // Note: This may not show the latest state immediately
                return modifiedRole; // Return the updated role
            }

            return role; // Return unchanged roles
        });

        // setRolesData(updatedRoles); // Update roles data with modified values
        dispatch(setRolesInfo({ field: 'rolesData', value: updatedRoles })); // Update the store with the modified roles
    };

    // const handleDataElementsCheckboxChange = (roleId, dataFieldId, action) => {
    //     const updatedRoles = rolesData.map((role) => {
    //         if (role.id === roleId) {
    //             // Get the existing data_elements object or initialize it
    //             const existingDataElements = role?.data_objects?.data_elements || {};

    //             // Get the existing actions for the specific data field or initialize an empty array
    //             const existingActions = existingDataElements[dataFieldId] || [];

    //             let updatedValues;
    //             if (existingActions.includes(action)) {
    //                 // If the action is already included, remove it (uncheck the box)
    //                 updatedValues = existingActions.filter((existingAction) => existingAction !== action);
    //             } else {
    //                 // If the action is not included, add it (check the box)
    //                 updatedValues = [...existingActions, action];
    //             }

    //             // Update the data_elements with the new values for the specific data field
    //             const updatedDataElements = {
    //                 ...existingDataElements,
    //                 [dataFieldId]: updatedValues,
    //             };

    //             return {
    //                 ...role,
    //                 data_objects: {
    //                     ...role.data_objects,
    //                     data_elements: updatedDataElements,
    //                 },
    //             };
    //         }
    //         return role;
    //     });

    //     console.log(updatedRoles);
    //     setRolesData(updatedRoles);
    // };

    const handleDataElementsCheckboxChange = (roleId, dataFieldId, action) => {
        const updatedRoles = rolesData.map((role) => {
            if (role.id === roleId) {
                const existingDataElements = role?.data_objects?.data_elements || {};
                const existingActions = existingDataElements[dataFieldId] || [];

                let updatedValues;
                if (existingActions.includes(action)) {
                    updatedValues = existingActions.filter((existingAction) => existingAction !== action);
                } else {
                    updatedValues = [...existingActions, action];
                }

                const updatedDataElements = {
                    ...existingDataElements,
                    [dataFieldId]: updatedValues,
                };

                const modifiedRole = {
                    ...role,
                    data_objects: {
                        ...role.data_objects,
                        data_elements: updatedDataElements,
                    },
                };

                // Track modified roles
                setModifiedRoles(prev => {
                    const existingRoleIndex = prev.findIndex(modifiedRole => modifiedRole.id === roleId);
                    if (existingRoleIndex !== -1) {
                        return prev.map((role, index) =>
                            index === existingRoleIndex ? modifiedRole : role
                        );
                    } else {
                        return [...prev, modifiedRole];
                    }
                });

                return modifiedRole;
            }
            return role;
        });
        // setRolesData(updatedRoles);
        dispatch(setRolesInfo({ field: 'rolesData', value: updatedRoles }));
    };


    const showSnackbar = (message) => {
        setSnackbarMessage(message);
    };

    

    return (
        <>
        {
            showRoleDialog && (
                <AddRoleDialog onClose={() => dispatch(setProp({ slice: "userManagement", key: "showAddRoleDialog", value: false }))} showSnackbar={showSnackbar}/>
            )
           
        }
        <Card style={{ padding: '1rem', outline:'none' }}>
                <RolesActions showSnackbar={showSnackbar} />
                <Typography style={{ color: '#333333', fontSize: '18px', borderBottom: "1px solid #0000001F", fontWeight: 'bold' }}>User Roles</Typography>

                {(rolesData.length === 0 || loading) ? (<Loader />) : (<React.Fragment>
                    <div style={{ width: '100%', height: '100%', marginTop: '1rem' }}>
                        <DataGrid
                            rowData={organizationTypeList}
                            //@ts-ignore
                            columnDefs={organizationData}
                            suppressMovableColumns={false}
                            //variant="compact"
                            suppressColumnMoveAnimation={false}
                            suppressRowClickSelection={false}
                            isRowDrag={false}
                            wrapperProps={{
                                style: {
                                    width: "100%",
                                },
                            }}
                            disableAutoloadStyles
                            suppressContextMenu
                            onGridReady={(params) => {
                                gridApi.current = params.api;
                            }}
                            key={"roles-table"}
                            defaultColDef={{
                                editable: false,
                                sortable: false,
                                resizable: false,
                                // filter: true,
                                flex: 0,
                                minWidth: 100,

                            }}
                            groupDisplayType={groupDisplayType}
                            overlayNoRowsTemplate="No applications available"
                            domLayout="autoHeight"
                            isInlineRowActions={false}
                            onFirstDataRendered={(params) => {
                                params.columnApi.autoSizeAllColumns();
                            }}
                        />
                    </div>
                    <div style={{ width: '100%', height: '100%', marginTop: '1rem' }}>


                        <ExpansionPanelsContainer>
                            {PANELData.map(panel => (
                                <ExpansionPanel
                                    expanded={expanded === panel.id}
                                    key={panel.id}
                                    id={panel.id}
                                    hoverEffect
                                    focusEffect
                                    mountContent={false}
                                >
                                    <ExpansionPanelHeader
                                        data-test="header"
                                        ref={headerRef}
                                        role="button"
                                        aria-expanded={expanded === panel.id}
                                        style={{ justifyContent: 'space-between' }}
                                        onKeyDown={handleExpansion(panel.id)}
                                        onClick={handleExpansion(panel.id)}
                                    >
                                        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center', width: '18.75rem' }}>
                                            <img src={businessHubIcon} />
                                            <ExpansionPanelTitle>{panel.name}</ExpansionPanelTitle>
                                        </div>
                                        <ExpansionPanelButton
                                            onClick={handleExpansion(panel.id)}
                                            iconButtonProps={{
                                                'aria-hidden': true,
                                            }}
                                        />
                                    </ExpansionPanelHeader>

                                    <ExpansionPanelBody>
                                        <DataGrid
                                            rowData={filteredApplicationList}
                                            //@ts-ignore
                                            columnDefs={columns}
                                            suppressMovableColumns={false}
                                            //variant="compact"
                                            suppressColumnMoveAnimation={false}
                                            suppressRowClickSelection={false}
                                            isRowDrag={false}
                                            wrapperProps={{
                                                style: {
                                                    width: "100%",
                                                },
                                            }}
                                            disableAutoloadStyles
                                            suppressContextMenu
                                            onGridReady={(params) => {
                                                gridApi.current = params.api;
                                            }}
                                            key={"roles-table"}
                                            defaultColDef={{
                                                editable: false,
                                                sortable: false,
                                                resizable: false,
                                                // filter: true,
                                                flex: 0,
                                                minWidth: 100,

                                            }}
                                            groupDisplayType={groupDisplayType}
                                            overlayNoRowsTemplate="No applications available"
                                            domLayout="autoHeight"
                                            isInlineRowActions={false}
                                            onFirstDataRendered={(params) => {
                                                params.columnApi.autoSizeAllColumns();
                                            }}
                                        />

                                    </ExpansionPanelBody>
                                </ExpansionPanel>
                            ))}
                        </ExpansionPanelsContainer>

                    </div>

                    <div style={{ marginTop: '1rem' }}>
                        <ExpansionPanelsContainer>
                            {DATA.map(panel => (
                                <ExpansionPanel
                                    expanded={expanded === panel.id}
                                    key={panel.id}
                                    id={panel.id}
                                    hoverEffect
                                    focusEffect
                                    mountContent={false}
                                >
                                    <ExpansionPanelHeader
                                        data-test="header"
                                        ref={headerRef}
                                        role="button"
                                        aria-expanded={expanded === panel.id}
                                        style={{ justifyContent: 'space-between' }}
                                        onKeyDown={handleExpansion(panel.id)}
                                        onClick={handleExpansion(panel.id)}
                                    >
                                        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center', width: '18.75rem' }}>
                                            <img src={dataFieldsIcon} />
                                            <ExpansionPanelTitle>{panel.name}</ExpansionPanelTitle>
                                        </div>
                                        <ExpansionPanelButton
                                            onClick={handleExpansion(panel.id)}
                                            iconButtonProps={{
                                                'aria-hidden': true,
                                            }}
                                        />
                                    </ExpansionPanelHeader>

                                    <ExpansionPanelBody>
                                        <DataGrid
                                            rowData={filteredDataElements}
                                            //@ts-ignore
                                            columnDefs={dataElementsColumns}
                                            suppressMovableColumns={false}
                                            wrapperProps={{ style: { width: "100%" } }}
                                            overlayNoRowsTemplate="No data elements available"
                                            domLayout="autoHeight"
                                            isInlineRowActions={false}
                                            onFirstDataRendered={(params) => {
                                                params.columnApi.autoSizeAllColumns();
                                            }}
                                            disableAutoloadStyles
                                            suppressContextMenu
                                            onGridReady={(params) => {
                                                gridApi.current = params.api;
                                            }}
                                            key={"elements-table"}
                                            defaultColDef={{
                                                editable: true,
                                                sortable: true,
                                                resizable: true,
                                                filter: true,
                                                flex: 0,
                                                minWidth: 100,
                                            }}
                                            isRowDrag={false}
                                        />

                                    </ExpansionPanelBody>
                                </ExpansionPanel>
                            ))}
                        </ExpansionPanelsContainer>
                    </div>
                </React.Fragment>)}

            </Card>

            <SnackBar message={snackbarMessage} onClose={handleCloseSnackBar} />
        </>
    );
};

export default UserRoles;
